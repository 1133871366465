@use "../../global" as g;

.c-heading-primary {
  display: flex;
  flex-direction: column;
  color: g.$color-title;
  font-weight: 400;
  writing-mode: vertical-rl;

  @include g.mq(md) {
    display: block;
    line-height: 1.5;
    text-align: center;
    writing-mode: unset;
  }

  &__title {

    @include g.font-size(40);

    font-weight: bold;
    display: block;
    margin-left: 12px;

    &--indent{
      line-height:1.3;
    }

    @include g.mq(md) {
      @include g.font-size(30);

      margin-left: 0;
    }
  }

  &__subtitle {
    @include g.font-size(14);

    padding-top: 6px;
    line-height: calc(32 / 14);

    @include g.mq(md) {
      @include g.font-size(12);

      padding-top: 0;
    }
  }
}

.c-heading-secondary {
  color: g.$color-title;
  font-weight: 400;
  line-height: 1;
  text-align: center;

  &__title {
    @include g.font-size(36);

    display: block;
    line-height: 1.5;

    @include g.mq(md) {
      @include g.font-size(30);

      margin-bottom: 9px;
    }
  }

  &__subtitle {
    display: inline-block;
    margin-top:20px;
    @include g.font-size(14);

    @include g.mq(md) {
      @include g.font-size(12);
    }
  }
}

/*
.c-heading-secondary--modify {
  .c-heading-secondary__japanese {
    @include g.font-size(24);

    margin-bottom: 6px;

    @include g.mq(md) {
      @include g.font-size(20);

      margin-bottom: 9px;
    }
  }
}
*/

.c-heading-tertiary {
  padding-right: 15px;
  color: g.$color-title;
  font-weight: bold;

  @include g.font-size(40);

  @include g.mq(md) {
    width: unset !important;
    margin-bottom: 10px;
    padding-right: 0;
    text-align: center;

    @include g.font-size(30);
  }
}

.c-heading-quaternary {
  @include g.font-size(24);

  position: relative;
  margin-bottom: 27px;
  padding-bottom: 23px;
  color: g.$color-title;
  font-weight: 400;

  @include g.mq(md) {
    @include g.font-size(20);

    margin-bottom: 0;
    padding-bottom: 11px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 42px;
    height: 1px;
    background: g.$color-title;
  }

  &--accordion {
    @include g.mq(md) {
      display: flex;
      align-items: center;
      min-height: 40px;
      padding: 0 35px 0 23px;
      padding-bottom: 0;
      border-radius: 6px;
      background: rgba(156,140,109,.2);

      @include g.font-size(16);

      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 52%;
        right: 20px;
        width: 4px;
        height: 4px;
        transform: translateY(-50%) rotate(-45deg);
        transition: transform .3s ease;
        border: 1px solid g.$color-title;
        border-top: 0;
        border-right: 0;
      }
    }

    &::after {
      @include g.mq(md) {
        display: none;
      }
    }
  }
}

.c-heading-quinary {
  position: relative;
  margin-bottom: 43px;
  padding-bottom: 43px;
  color: g.$color-title;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;

  @include g.font-size(24);

  @include g.mq(md) {
    @include g.font-size(20);

    margin-bottom: 21px;
    padding-bottom: 15px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 170px;
    height: 1px;
    transform: translateX(-50%);
    background: g.$color-title;
  }

  &--left {
    text-align: left;

    &::after {
      left: 0;
      transform: none;
    }
  }
}

.c-list-offer {
  .c-heading-quinary {
    margin-bottom: 61px;

    @include g.mq(md) {
      margin-bottom: 78px;
    }
  }

  &__list {
    .c-heading-quinary {
      margin-bottom: 34px;
      padding-bottom: 27px;

      @include g.mq(xl) {
        margin-bottom: 11px;
        text-align: center;
      }

      &::after {
        width: 80px;

        @include g.mq(xl) {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.p-top-founder {
  .c-heading-secondary {
    @include g.mq(md) {
      margin-bottom: 35px;
    }
  }
}

.p-top-hospitality {
  .c-heading-primary {
    width: 90px;
    padding-top: 5px;

    @include g.mq(md) {
      width: unset;
      margin-bottom: 24px;
      padding-top: 0;
    }
  }
}

.p-top-benefits {
  .c-heading-primary {
    width: 80px;
    padding-top: 4px;

    @include g.mq(md) {
      width: unset;
      margin-bottom: 11px;
      padding-top: 0;
    }
  }
}

.p-top-information {
  .c-heading-tertiary {
    width: 250px;
    line-height:1;

    @include g.mq(lg) {
      width: 200px;
    }

    @include g.mq(md) {
      margin-bottom: 10px;
    }
  }
}

.c-introduction {
  .c-heading-primary {
    justify-content: flex-end;
    width: 130px;

    @include g.mq(md) {
      width: 100%;
      margin-bottom: 19px;
    }

    .c-heading-primary__japanese {
      margin-left: 25px;

      @include g.mq(md) {
        margin-left: 0;
      }
    }
  }
}

.p-support-affiliated {
  .c-heading-secondary {
    margin-bottom: 103px;

    @include g.mq(md) {
      margin-bottom: 23px;
    }
  }
}

.c-relate {
  .c-heading-quaternary {
    margin-bottom: 26px;

    @include g.mq(md) {
      margin-bottom: 22px;
    }
  }
}

.p-flow-steps {
  .c-heading-secondary {
    margin-bottom: 107px;

    @include g.mq(md) {
      margin-bottom: 47px;
    }
  }
}

.p-flow-membership {
  .c-heading-secondary {
    margin-bottom: 79px;

    @include g.mq(md) {
      margin-bottom: 44px;
    }
  }
}

.p-flow-faq {
  .c-heading-secondary {
    margin-bottom: 60px;

    @include g.mq(md) {
      margin-bottom: 30px;
    }
  }
}

.p-column-category {
  .c-heading-secondary {
    margin-bottom: 55px;

    @include g.mq(md) {
      margin-bottom: 25px;
    }
  }
}

.p-contact {
  .c-heading-primary {
    width: 130px;

    @include g.mq(md) {
      width: unset;
      margin-bottom: 18px;
    }
  }
}

.p-service-reservation {
  .c-heading-primary {
    width: 144px;

    @include g.mq(md) {
      width: unset;
      margin-bottom: 18px;
    }
  }
}

.c-price-course {
  .c-heading-quinary {
    @include g.mq(md) {
      margin-bottom: 34px;
    }
  }
}

.p-service {
  .c-heading-quinary {
    &::after {
      @include g.mq(md) {
        left: 50%;
        width: 100px;
        transform: translateX(-50%);
      }
    }
  }
}

.c-price-overview {
  .c-heading-quinary {
    @include g.mq(md) {
      margin-right: -10px;
      margin-bottom: 48px;
      margin-left: -10px;
    }
  }
}

.p-feature {
  .c-heading-quinary {
    @include g.mq(md) {
      margin-bottom: 24px;
    }
  }
}


.p-feature-benefits {
  .c-heading-secondary {
    margin-bottom: 64px;

    @include g.mq(md) {
      margin-bottom: 24px;
    }
  }
}

.p-price {
  .c-heading-quinary {
    padding-bottom: 17px;

    @include g.mq(md) {
      margin-bottom: 33px;
    }
  }
}

.p-column-keywords {
  .c-heading-secondary {
    margin-bottom: 77px;

    @include g.mq(md) {
      margin-bottom: 30px;
    }
  }
}
