@use "../../global" as g;

.u-btn-light {
  border-color: #b09b86;
  background: #fff;
  color: #b09b86;

  &::before {
    background-image: url("../images/common/ico_arrow_01_light.svg");
  }
}
