@use "../../global" as g;

.u-image {
  width: 100%;
}

.u-object {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
