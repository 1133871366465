@use "../../global" as g;

.c-price-overview {
  padding-bottom: 40px;
  background: g.$color-secondary;

  @include g.mq(md) {
    padding-bottom: 0;
  }

  &__inner {
    position: relative;
    max-width: 1220px;
    margin: -48px auto 0;
    padding: 64px 96px 48px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 1px 2px 15px 15px rgba(234,224,220,.2);

    @include g.mq(md) {
      display: block;
      margin: -24px 32px 0;
/*      padding: 32px 32px 56px;*/
      padding: 32px 18px 32px;
    }
  }

  &__background {
    position: relative;
    padding-top: 32.5%;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include g.mq(md) {
      padding-top: 62.09302325581395%;
    }
  }

  &__text {
    @include g.mq(md) {
      line-height: calc(22 / 12);
    }
  }
}

.p-feature {
  .c-price-overview {
    // margin-bottom: 40px;
    background: unset;

    // @include g.mq(md) {
    //   margin-bottom: 8px;
    // }

    // &__inner {
    //   @include g.mq(md) {
    //     margin: -16px 32px 0;
    //     padding: 24px 32px;
    //   }
    // }

    &__background {
      padding-top: 42.08333333333333%;

      @include g.mq(md) {
        padding-top: 62.09302325581395%;
      }
    }
  }
}

.c-price-overview--flex {
  padding-top: 18px;

  .c-price-overview {
    &__head {
      margin-bottom: 56px;
    }

    &__inner {
      margin-bottom: 60px;

      @include g.mq(md) {
        margin-bottom: 45px;
      }
    }
  }
}
