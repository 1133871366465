@use "../../global" as g;

.wow {
  visibility: hidden;
}

@media print {
  .wow {
    visibility: visible !important;
  }
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation-name: fade-in;
  animation-name: fade-in;
}

@keyframes slider-zoom-out {
  0% {
    -webkit-transform: scale(1.15);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1.08);
  }
}

;

.mask_gradation_text {
  animation: mask-gradation-animation 1.1s ease-in-out forwards;
}

.text-animation {
  animation: text-animation .6s backwards;
}

.text-fade-in {
  display: inline-block;

  span {
    display: inline-block;
    transform: rotateY(-60deg) translateX(-10px);
    opacity: 0;
    filter: blur(10px);
  }

  &.animated {
    span {
      transform: rotateY(0) translateX(0);
      transition: opacity 1.4s ease, filter 1.4s ease, transform 1.6s cubic-bezier(.215, .61, .355, 1), -webkit-filter 1.4s ease, -webkit-transform 1.6s cubic-bezier(.215, .61, .355, 1);
      opacity: 1;
      filter: blur(0);

      &:nth-child(1) {
        transition-delay: .2s;
      }

      &:nth-child(2) {
        transition-delay: .4s;
      }

      &:nth-child(3) {
        transition-delay: .6s;
      }

      &:nth-child(4) {
        transition-delay: .8s;
      }

      &:nth-child(5) {
        transition-delay: 1s;
      }

      &:nth-child(6) {
        transition-delay: 1.2s;
      }

      &:nth-child(7) {
        transition-delay: 1.4s;
      }

      &:nth-child(8) {
        transition-delay: 1.6s;
      }

      &:nth-child(9) {
        transition-delay: 1.8s;
      }

      &:nth-child(10) {
        transition-delay: 2s;
      }

      &:nth-child(11) {
        transition-delay: 2.2s;
      }

      &:nth-child(12) {
        transition-delay: 2.4s;
      }

      &:nth-child(13) {
        transition-delay: 2.6s;
      }

      &:nth-child(14) {
        transition-delay: 2.8s;
      }

      &:nth-child(15) {
        transition-delay: 3s;
      }

      &:nth-child(16) {
        transition-delay: 3.2s;
      }

      &:nth-child(17) {
        transition-delay: 3.4s;
      }

      &:nth-child(18) {
        transition-delay: 3.6s;
      }
    }
  }
}

@keyframes mask-gradation-animation {
  0% {
    opacity: 0;
    -webkit-clip-path: inset(0 0 100% 0);
    clip-path: inset(0 0 100% 0);
  }

  70% {
    opacity: 0.5;
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }

  100% {
    opacity: 1;
    -webkit-clip-path: inset(0);
    clip-path: inset(0);
  }
}

@keyframes text-animation {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hamburger-toggler-hover {
  from {
    width: 22px;
  }

  to {
    width: 42px;
  }
}


.fade_up {
  opacity: 0;
  animation: fadeup 2s ease 0s forwards;
}

@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fade_left {
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    transition: 2s cubic-bezier(0.20, 1, 0.20, 1) 0s;
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.active {
    &::before {
      transform: translateX(101%);
    }
  }
}