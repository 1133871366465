@use "../../global" as g;

.c-news {
  max-width: 1255px;
  margin: 0 auto;
  padding: 75px 15px 133px;

  @include g.mq(md) {
    padding: 50px 37px 66px 23px;
    background: g.$color-secondary;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2.847vw;
    list-style: none;

    @include g.mq(md) {
      display: block;
      max-width: unset;
      margin-bottom: 34px;
    }
  }

  &__link {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:hover {
      opacity: 1;
    }
  }

  &__heading {
    display: -webkit-box;
/*    margin-bottom: 13px;*/
    margin-bottom: 20px;
    padding-right: 7px;
    overflow: hidden;
    color: #66471f;
    font-weight: bold;
    line-height: calc(24 / 16);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include g.font-size(16);

    @include g.mq(md) {
/*      margin-bottom: 42px;*/
      padding: 0 5px 0 15px;
    }
  }

  &__tag {
    display: flex;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    align-items: center;
    justify-content: center;
    width: 144px;
    height: 46px;
    padding: 5px 10px;
    transform: translate(-50%, -40%);
    transition: transform .3s ease;
    border: 1px solid #898080;
    border-radius: 26px;
    opacity: 0;
    background: #fff;
    font-weight: bold;

    @include g.font-size(14);
  }

  &__image {
    position: relative;
    padding-top: 46.62576687116564%;

    @include g.mq(md) {
      padding-top: 46.51162790697674%;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &::after {
      content: "";
      visibility: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: .3s ease;
      opacity: 0;
      background: rgba(0,0,0,.16);
    }
  }

  &__item {
    position: relative;
    width: calc((100% - 12.222vw) / 3);
    margin: 0 6.111vw 5.903vw 0;
/*    padding: 0.972vw 0.833vw 4.097vw;*/
    padding: 0.972vw 0.833vw 2vw;
    transition: all .3s ease;
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(125,119,119,.25);
    cursor: pointer;

    @include g.mq(md) {
      width: 100%;
/*      min-height: 441px;*/
      min-height: 400px;
      margin-bottom: 52px;
      padding: 15px 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
    }

    &::after {
      top: 0;
      bottom: 0;
      left: -3.125vw;
      width: 2px;
      background: url("../images/common/decor_line_01.png") repeat-y 0 0 / auto 100%;

      @include g.mq(md) {
        display: none;
      }
    }

    &::before {
      right: 0;
      bottom: -3.472vw;
      left: 0;
      height: 1px;
      background: url("../images/common/decor_line_02.png") no-repeat 0 0 / 100% 1px;

      @include g.mq(md) {
        bottom: -26px;
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:first-child,
    &:nth-child(3n + 4) {
      &::after {
        display: none;
      }
    }

    &:nth-last-child(-n+3) {
      &::before {
        display: none;

        @include g.mq(md) {
          display: block;
          margin-bottom: auto;
        }
      }
    }

    &:last-child {
      &::before {
        @include g.mq(md) {
          display: none;
        }
      }
    }

    &:hover {
      box-shadow: 4px 4px 15px 4px rgba(125,119,119,.25);

      .c-news__tag {
        visibility: visible;
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      .c-news__image::after {
        visibility: visible;
        opacity: 1;
      }

      .c-news__heading {
        color: g.$color-text;
      }
    }
  }

  &__thumbnail {
    position: relative;
    margin-bottom: 30px;
  }

  &__info {
/*    margin-bottom: 3px;*/
    margin-bottom: 10px;
  }

  &__time {
    display: inline-block;
    position: relative;
/*    margin-bottom: 11px;*/
	margin-bottom:0px;
    padding-right: 17px;
    color: g.$color-text;

    @include g.mq(md) {
      margin-bottom: 5px;
      padding: 0 17px 0 14px;
    }

    @include g.font-size(12);

    &::after {
      content: "｜";
      position: absolute;
      top: 0;
      right: 0;
      color: inherit;
      font-size: inherit;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    @include g.mq(md) {
/*      margin-bottom: 16px;*/
      margin-bottom: 10px;
      padding: 0 10px;
    }

    &-image {
      position: relative;
      z-index: 5;
      flex-shrink: 0;
      width: 42px;
      height: 42px;
      margin-right: 13px;
      overflow: hidden;
      border-radius: 100%;

      a {
        display: block;
      }

      @include g.mq(md) {
        position: relative;
        top: -6px;
        margin-right: 16px
      }
    }

    &-name {
      @include g.font-size(14);

      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      color: g.$color-text;

      @include g.mq(md) {
        position: relative;
        top: -4px;
      }
    }
  }
}
