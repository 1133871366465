@use "../../global" as g;

.c-keywords {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -9px;
    list-style: none;

    @include g.mq(md) {
      margin: 0;
    }
  }

  &__item {
    margin: 0 9px 29px;
    overflow: hidden;
    border: 1px solid #898080;
    border-radius: 3px;

    @include g.mq(md) {
      margin: 0 4px 15px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 115px;
    min-height: 47px;
    padding: 5px 15px;
    background: #fff;
  }
}
