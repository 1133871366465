@use "../../../global" as g;

.p-recruit {
  &-list {
    list-style-type: none;

    &__item {
      position: relative;
      padding-left: 15px;
      padding-bottom: 10px;

      &:before {
        content: "・";
        position: absolute;
        left: 0;
      }

      &--marker-none {
        padding-left: 3px;

        &:before {
          content: "";
        }
      }

      &--small {
        @include g.font-size(14);
      }
    }

    &--asterisk {
      padding-left: 2.6em;
      text-indent: -2.6em;
      @include g.font-size(14);
      padding-bottom: 5px;
      line-height: 1.6;
    }
  }

  &-caption {
    @include g.font-size(16);
    margin-top: 10px;

    @include g.mq(md) {
      @include g.font-size(14);
    }
  }
}


.p-recruit-type {
  padding: 100px 15px 0;

  @include g.mq(md) {
    padding: 60px 15px 0;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 30px;
    margin: 0 auto;

    @include g.mq(md) {
      column-gap: 20px;
      row-gap: 20px;
      flex-wrap: wrap;
      justify-content: left;

      a {
        width: calc(50% - 10px);
      }
    }
  }

}

.p-recruit-seles-plan {
  padding: 100px 0 0;

  @include g.mq(md) {
    padding: 60px 15px 0;
  }

  &-kv {
    position: relative;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 50px 100px;
      text-align: center;
      color: g.$color-title;
      min-width: 350px;

      @include g.mq(md) {
        padding: 20px 40px;
        min-width: 200px;
      }
    }

    &__title {
      @include g.font-size(30);
      line-height: 1.5;

      @include g.mq(md) {
        @include g.font-size(24);
      }
    }

    &__subtitle {
      @include g.font-size(16);

      @include g.mq(md) {
        @include g.font-size(14);
      }
    }
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;
    padding: 100px 15px 0;

    @include g.mq(lg) {
      display: block;
    }

    @include g.mq(md) {
      padding: 20px 0 0;
    }

  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    @include g.mq(md) {
      display: block;
    }

    &__head {
      width: 25%;
      margin-right: 3%;
      padding: 20px;
      border-bottom: 1px solid g.$color-title;

      &:first-child,
      &:first-child+dd {
        padding: 0 20px 10px;
      }

      @include g.mq(md) {
        padding: 20px 20px 0 20px;
        width: 100%;
        font-weight: bold;
        border: none;
        color: g.$color-title;
      }

    }

    &__body {
      width: 72%;
      padding: 20px;
      border-bottom: 1px solid #807F7E;

      @include g.mq(md) {
        padding: 2px 20px 20px 20px;
        width: 100%;
      }
    }
  }

  &__btn {
    margin: 0 auto;
    margin-top: 40px;
  }
}

.p-recruit-response-marketing {
  padding: 100px 0 0;

  @include g.mq(md) {
    padding: 60px 15px 0;
  }

  &-kv {
    position: relative;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 50px 100px;
      text-align: center;
      color: g.$color-title;
      min-width: 350px;

      @include g.mq(md) {
        padding: 20px 40px;
        min-width: 200px;
      }
    }

    &__image {}

    &__title {
      @include g.font-size(30);
      line-height: 1.5;

      @include g.mq(md) {
        @include g.font-size(24);
      }
    }

    &__subtitle {
      @include g.font-size(16);

      @include g.mq(md) {
        @include g.font-size(14);
      }
    }
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;
    padding: 100px 15px 0;

    @include g.mq(lg) {
      display: block;
    }

    @include g.mq(md) {
      padding: 20px 0 0;
    }

  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    @include g.mq(md) {
      display: block;
    }

    &__head {
      width: 25%;
      margin-right: 3%;
      padding: 20px;
      border-bottom: 1px solid g.$color-title;

      &:first-child,
      &:first-child+dd {
        padding: 0 20px 10px;
      }

      @include g.mq(md) {
        padding: 20px 20px 2px 20px;
        width: 100%;
        font-weight: bold;
        border: none;
        color: g.$color-title;
      }

    }

    &__body {
      width: 72%;
      padding: 20px;
      border-bottom: 1px solid #807F7E;

      @include g.mq(md) {
        padding: 2px 20px 20px 20px;
        width: 100%;
      }
    }
  }

  &__btn {
    margin: 0 auto;
    margin-top: 40px;
  }
}

.p-recruit-management {
  padding: 100px 0;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &-kv {
    position: relative;

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 50px 100px;
      text-align: center;
      color: g.$color-title;
      min-width: 350px;

      @include g.mq(md) {
        padding: 20px 40px;
        min-width: 200px;
      }
    }

    &__image {}

    &__title {
      @include g.font-size(30);
      line-height: 1.5;

      @include g.mq(md) {
        @include g.font-size(24);
      }
    }

    &__subtitle {
      @include g.font-size(16);

      @include g.mq(md) {
        @include g.font-size(14);
      }
    }
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;
    padding: 100px 15px 0;

    @include g.mq(lg) {
      display: block;
    }

    @include g.mq(md) {
      padding: 20px 0 0;
    }

  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    @include g.mq(md) {
      display: block;
    }

    &__head {
      width: 25%;
      margin-right: 3%;
      padding: 20px;
      border-bottom: 1px solid g.$color-title;

      &:first-child,
      &:first-child+dd {
        padding: 0 20px 10px;
      }

      @include g.mq(md) {
        padding: 20px 20px 2px 20px;
        width: 100%;
        font-weight: bold;
        border: none;
        color: g.$color-title;
      }

    }

    &__body {
      width: 72%;
      padding: 20px;
      border-bottom: 1px solid #807F7E;

      @include g.mq(md) {
        padding: 2px 20px 20px 20px;
        width: 100%;
      }
    }
  }

  &__btn {
    margin: 0 auto;
    margin-top: 40px;
  }

  /*
  &-list{
    z-index: 1;
    margin-bottom: 0;
    padding-left: 6px;
    list-style-type: none;
    &__item{
      position: relative;
      padding-left: 21px;
      line-height: 2;
      padding-bottom: 10px;
      &:before{
        content: "・";
        position: absolute;
        left: 0;
      }      
    }
  }
  */


}