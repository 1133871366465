@use "../../global" as g;

.c-swiper {
  position: relative;
  padding-bottom: 62px;
  overflow: hidden;

  @include g.mq(md) {
    padding-bottom: 50px;
  }

  &.p-support-affiliated__slider {
    padding-bottom: 45px;

    @include g.mq(md) {
      padding-bottom: 38px;
    }
  }

  &.p-support-affiliated__slider.is-disabled {
    .swiper-slide {
      width: calc((100% - 116px) / 3);
      margin-right: 58px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      @include g.mq(md) {
        width: calc((100% - 26px) / 2);
        margin-right: 26px;

        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }

  &.c-author__swiper.is-disabled {
    .swiper-slide {
      width: calc((100% - 300px) / 4);
      margin-right: 100px;

      &:nth-child(4n) {
        margin-right: 0;
      }

      @include g.mq(md) {
        width: calc((100% - 36px) / 3);
        margin-right: 18px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .custom-pagination {
    position: absolute;
    bottom: 0;

    @include g.mq(md) {
      left: 6px;
    }
  }

  .custom-pagination-bullet {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 6px;
    margin-right: 10px;
    transition: .8s cubic-bezier(.2,1,.2,1);
    opacity: 1;
    background: #d9d9d9;
    cursor: pointer;

    @include g.mq(md) {
      width: 42px;
      height: 7px;
    }
  }

  .custom-pagination-bullet.is-active {
    width: 100px;
    background: g.$color-tertiary !important;

    @include g.mq(md) {
      width: 70px;
    }
  }
}

.p-service__slider {
  @include g.mq(md) {
    overflow: unset;
  }

  &-image {
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .1;
      background: linear-gradient(rgba(63,42,18,1) 0%, rgba(35,24,12,1) 100%);
      pointer-events: none;

      @include g.mq(md) {
        display: none;
      }
    }
  }

  .custom-pagination {
    bottom: 7px;
    left: 67px;
  }

  .slide-item {
    width: 100%;
    height: 100%;
  }

  .slider-viewport {
    height: 606px;

    @include g.mq(xxl) {
      height: 400px;
    }

    @include g.mq(md) {
      height: 244px;
      overflow: hidden;
    }
  }

  .swiper-button-next {
    top: 114px;
    right: -87px;
    width: 60px;
    height: 60px;
    background: url("../images/common/sp/icn_arrow_next.png") center/cover no-repeat;

    &::after {
      display: none;
    }
  }
}

.p-service__block-gallery--reverse {
  .p-service__slider {
    .custom-pagination {
      right: 67px;
      left: unset;
    }
  }
}

.p-top-benefits {
  .swiper {
    margin-top: 116px;

    @include g.mq(md) {
      margin-top: 0;
    }
  }
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .swiper-slide {
    position: relative;
	/*
    width: 649px;
    height: 434px;
	*/
    width: 450px;
    height: 300px;
	
    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include g.mq(md) {
	/*
      width: 282px;
      height: 290px;
	*/
      width: 250px;
      height: 167px;	
    }
  }
}

.p-top-keyvisual {
/*
  .swiper-slide {
    height: 848px;

    @include g.mq(md) {
      height: 581px;
    }
  }
*/

/*
  .swiper-slide-prev,
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    img {
      animation-name: slider-zoom-out;
      animation-duration: 15s;
      animation-fill-mode: forwards;
    }
  }
*/
}
