@use "../../global" as g;

.c-block-fixed {
  position: fixed;
  z-index: 9999;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  
  @include g.mq(md) {	
	top: unset;
	bottom:0;
	left: 0;
	transform: none;
  }
  
  &__inner{
    display: flex;	
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }  
  
  &__link {
    display: flex;
/*
    position: fixed;
    z-index: 999;
    top: 50%;
    right: 0;
*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 200px;
    padding: 5px;
/*  transform: translateY(-50%); */
    border-radius: 6px;
    background: rgba(156,140,109,.8);
    color: #fff;
    writing-mode: vertical-rl;
	margin-bottom:20px;

    @include g.mq(md) {	
      writing-mode: unset;
	  display:block;
      margin-bottom: 0;
	  width:50%;
	  height:auto;
      border-radius: 0;
	  text-align:center;
	  padding:17px;
/*
      top: unset;
      right: 2.791vw;
      bottom: 4.651vw;
      width: 82px;
      height: 82px;
      padding: 5px;
      transform: unset;
      writing-mode: unset;
      border-radius: 100%;
      background: g.$color-tertiary;
*/
    }

	&-tel{
      background: #7B663E;
	}

	&-request{
      background: #A18A69;
	}

  }

  &__img {
      width:100%;
	  max-width:200px;
	  margin:0 auto;
  }

  &__japanese {
    @include g.font-size(20);

    position: relative;
    padding: 40px 4px 0 0;
    letter-spacing: .2em;
    line-height: 1;

    @include g.mq(md) {
      @include g.font-size(16);

      display: block;
      padding: 0;
      letter-spacing: 0;
      line-height: 1.5;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 1px;
      height: 24px;
      transform: translateX(-50%);
      background: #fff;

      @include g.mq(md) {
        display: none
      }
    }
  }

  &__english {
    @include g.font-size(12);

    padding-top: 31px;
  }
}
