@use "../../global" as g;

.c-card-item {
  display: block;
  position: relative;
  width: 189px;
  height: 189px;
  padding: 26px 20px 20px;
  border-radius: 50%;
  background-color: g.$color-tertiary;
  box-shadow: 1px 2px 15px 15px rgba(234,224,220,.2);
  color: #f8f8f8;
  line-height: 1.5;

  @include g.font-size(18);

  @include g.mq(md) {
    width: 106px;
    height: 106px;
    padding: 14px 10px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translateX(-50%) rotate(45deg);
    border: solid #fff;
    border-width: 0 1px 1px 0;

    @include g.mq(md) {
      bottom: 9px;
      width: 6px;
      height: 6px;
    }
  }

  &--no-arrow {
    &::before {
      display: none;
    }
  }

  &__number {
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 12px;
    padding-bottom: 7px;

    @include g.mq(md) {
      justify-content: center;
      margin-bottom: 6px;
      padding-bottom: 4px;

      @include g.font-size(10);
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 132px;
      height: 1px;
      transform: translateX(-50%);
      border-bottom: 1px solid #fff;

      @include g.mq(md) {
        width: 65px;
      }
    }

    &-text {
      display: block;
      margin-right: 6px;

      @include g.mq(md) {
        margin-right: 4px;
      }
    }

    &-count {
      display: block;

      @include g.font-size(43);

      line-height: 1.05;

      @include g.mq(md) {
        @include g.font-size(20);

        line-height: 1.15;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    @include g.font-size(29);

    font-weight: 400;
    line-height: calc(36 / 32);

    @include g.mq(md) {
      @include g.font-size(15);

      line-height: calc(18 / 15);
    }
  }
}

.p-feature-reservation {
  .c-card-item {
    @media (max-width: 427.98px) {
      width: 23.256vw;
      height: 23.256vw;
      padding: 3.256vw 2.326vw;
    }

    &__title {
      @media (max-width: 427.98px) {
        @include g.font-size(10);
      }
    }
  }
}

.c-card-item--custom {
  text-align: center;

  .c-card-item__title {
    @include g.font-size(16);

    @include g.mq(md) {
      @include g.font-size(9);
    }

    @media (max-width: 427.98px) {
      @include g.font-size(7);
    }
  }
}

.p-feature {
  .c-card-item {
    position: absolute;
    z-index: 1;
    top: 38px;
    left: 46px;

    &--reverse {
      right: 41px;
      left: unset;
    }

    @include g.mq(md) {
      top: 0;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.p-feature-benefits {
  .c-card-item {
    @include g.font-size(24);

    text-align: center;

    &::before {
      display: none;
    }

    @media (max-width: 427.98px) {
      width: 23.256vw;
      height: 23.256vw;
      padding: 3.256vw 2.326vw;
    }

    &__number {
      margin-bottom: 16px;

      @include g.font-size(20);

      @include g.mq(md) {
        margin-bottom: 6px;

        @include g.font-size(10);
      }

      @media (max-width: 427.98px) {
        margin-bottom: 2px;
      }
    }

    &__number-text {
      margin-right: 16px;

      @include g.mq(md) {
        margin-right: 12px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      min-height: 54px;

      @include g.font-size(24);

      @include g.mq(md) {
        min-height: 36px;

        @include g.font-size(15);

      }

      @media (max-width: 427.98px) {
        @include g.font-size(12);
      }
    }
  }
}
