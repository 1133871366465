// Colors
$color-text: #303030;
/*$color-title: #0b4178;*/
$color-title: #393327;
$color-subtitle: #0b4178;
$color-black: #000;
$color-bg: #cbd5df;
$color-primary: #3f3a39;
$color-secondary: #f5f5f5;
$color-tertiary: #9c8c6d;

// Width
$content-width: 1196px;