@use "../global" as g;


.l-footer {

  background-image: linear-gradient(180deg, rgba(70, 106, 126, 1), rgba(63, 42, 52, 1));
  color: #fff;

  &__inner {
    max-width: 1260px;
    margin: 0 auto;
    padding: 100px 15px;

    @include g.mq(md) {
      padding: 50px 15px;
    }
  }

  &__wrapper {
    display: flex;

    @include g.mq(md) {
      display: block;
    }
  }

  &__content {
    width: 70%;
    @include g.font-size(12);

    @include g.mq(md) {
      width: 100%;
    }
  }

  &__linkarea {}

  &__message {
    position: relative;
    padding-bottom: 40px;
    line-height: 2;
    @include g.font-size(30);

    @include g.mq(md) {
      @include g.font-size(20);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 1px;
      background-color: #fff;
      z-index: 999;
    }
  }

  &__menu,
  &__order-menu {
    margin-top: 40px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 5px;

    &-item {
      &-link {
        color: #fff;
      }
    }
  }

  &__order-menu {
    display: block;
  }

  &__logo {
    display: block;
    margin-top: 30px;
    max-width: 230px;
    ;
  }


  &__order-menu {
    margin-top: 5px;
  }

  &__order-link,
  &__phoneNo {
    color: #fff;
  }

  &__order-link {
    display: block;
    margin-top: 40px;

    &-title {
      margin-top: 20px;
    }
  }


  &__company,
  &__address,
  &__phone {
    display: block;
    margin-top: 5px;
  }

  &__address {
    font-style: normal;
  }

  &__company {
    margin-top: 30px;
  }

  &__privacy-list {
    list-style: none;
    margin-top: 20px;
    @include g.font-size(12);

    @include g.mq(md) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.93vw;
    }

    &-item {
      margin-bottom: 4px;

      a {
        color: #fff;
      }

      @include g.mq(md) {
        margin-bottom: 1px;

        &:nth-child(odd) {
          width: 45%;
        }

        &:nth-child(even) {
          width: 54%;
        }
      }

      @media (max-width: 390px) {
        width: 100% !important;
      }
    }
  }


  &__accordion {

    @include g.mq(md) {
      padding-bottom: 25px;
    }
  }

  &__accordion-inner {
    @include g.mq(md) {
      border-top: none;
    }
  }

  &__accordion-content {
    @include g.font-size(11);

    position: relative;
    margin: 0 auto;

    @include g.mq(md) {
      @include g.font-size(8);

      max-width: none;
      line-height: calc(12 / 8);
    }
  }

  .title {
    margin-top: 20px;
    /*
    display: inline-block;
    position: absolute;
    top: -5.5vw;
    left: 62.5vw;
    transition: opacity 0.3s ease;
    font-weight: 400;
    */
    cursor: pointer;

    @include g.mq(md) {
      position: relative;
      top: 0;
      left: 0;
    }

    @include g.font-size(11);

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 7px;
      margin-left: 10px;
      background: url("../images/common/img_btn_arrow_down_white.svg") no-repeat center / auto 100%;
    }

    &.is-accordion::after {
      transform: rotate(180deg);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .toggle {
    display: none;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 1.944vw 0.694vw 1.597vw 120px;
    transition: all .3s ease;
    list-style: none;

    /*
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: -100vw;
      left: -100vw;
      border-top: 1px solid #c1c6cb;

      @include g.mq(md) {
        display: none;
      }
    }
    */

    &.is-active {
      display: inline-block;
    }

    @include g.mq(md) {
      padding: 20px 0 0 80px;
    }

    &>li {
      display: inline;
      float: left;
      padding-right: 10px;

      a {
        color: #fff;
      }

      &:not(:has(a)) {
        width: 120px;
        margin-left: -120px;
        clear: left;

        @include g.mq(md) {
          width: 80px;
          margin-left: -80px;
        }
      }
    }

    .is-android & li {
      @include g.mq(md) {

        &:nth-child(1),
        &:nth-child(8),
        &:nth-child(12),
        &:nth-child(19),
        &:nth-child(23),
        &:nth-child(27),
        &:nth-child(29) {
          width: 80px;
          margin-left: -80px;
          clear: left;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(9),
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15),
        &:nth-child(16),
        &:nth-child(17),
        &:nth-child(18),
        &:nth-child(20),
        &:nth-child(21),
        &:nth-child(22),
        &:nth-child(24),
        &:nth-child(25),
        &:nth-child(26),
        &:nth-child(28),
        &:nth-child(30),
        &:nth-child(31),
        &:nth-child(32),
        &:nth-child(33),
        &:nth-child(34),
        &:nth-child(35),
        &:nth-child(36),
        &:nth-child(37) {
          float: left;
        }
      }
    }
  }

  &__copyright {
    margin-top: 30px;
    @include g.font-size(12);

    @include g.mq(md) {
      margin-top: 0;
      @include g.font-size(10);
    }
  }

}