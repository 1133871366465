@use "../../../global" as g;

.p-company-overview {
  padding: 100px 15px 0;

  @include g.mq(md) {
    padding: 60px 15px 0;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(lg) {
      display: block;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    @include g.mq(md) {
      display: block;
    }

    &__head {
      width: 25%;
      margin-right: 3%;
      padding: 20px;
      border-bottom: 1px solid g.$color-title;

      @include g.mq(md) {
        padding: 20px 20px 2px 20px;
        width: 100%;
        font-weight: bold;
        border: none;
        color: g.$color-title;
      }

    }

    &__body {
      width: 72%;
      padding: 20px;
      border-bottom: 1px solid #807F7E;

      @include g.mq(md) {
        padding: 2px 20px 20px 20px;
        width: 100%;
      }

      p {
        line-height: 1.6;

        &+p {
          margin-top: 10px;
        }
      }

    }
  }
}

.p-company-philosophy {

  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(lg) {
      display: block;
    }
  }

  &__heading-secondary {
    margin-bottom: 40px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 40px;
  }


  &__content {
    max-width: 1060px;
    min-height: 200px;
    padding: 60px;
    border-radius: 3px;
    /*
    background: g.$color-title;
    color: #fff;
    box-shadow: 1px 2px 15px 15px rgba(234, 224, 220, .2);
*/

    @include g.mq(lg) {
      width: auto;
      margin: -60px 30px 0;
    }

    @include g.mq(md) {
      margin: auto;
      padding: 40px 30px;
    }
  }

  /*  
  &__title {
    color: #fff;
  }

  &__subtitle {
    color: #fff;
  }
*/

}



.p-company-aboutus {

  background: g.$color-secondary;
  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(lg) {
      display: block;
    }
  }

  &__heading-secondary {
    margin-bottom: 40px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 40px;
  }

  &__content {
    max-width: 1060px;
    border-radius: 3px;

    @include g.mq(lg) {
      width: auto;
    }

  }

  &__image {
    padding: 50px 50px 100px;
    max-width: 800px;
    margin: 0 auto;

    @include g.mq(md) {
      padding: 40px 0 0;
    }
  }

  &-report {
    padding: 0px 100px 15px;

    @include g.mq(md) {
      padding: 40px 0 0;
    }

    &__wrapper {
      background-color: #fff;
      padding: 30px;

      @include g.mq(md) {
        padding: 10px;
      }

    }

    &__list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
      row-gap: 20px;
      column-gap: 20px;

      @include g.mq(md) {
        row-gap: 5px;
        column-gap: 5px;
      }

    }

    &__item {
      width: calc(33.333333% - 40px);

      @include g.mq(md) {
        width: calc(50% - 20px);
      }
    }

    &__box {
      padding: 10px;
      text-align: center;
    }

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 40px;
      line-height: 1.4;
      @include g.font-size(14);

      @include g.mq(md) {
        @include g.font-size(12);
        height: auto;
      }

    }

    &__num {
      @include g.font-size(40);
      line-height: 1;
      margin-top: 5px;

      @include g.mq(md) {
        @include g.font-size(22);
      }
    }

    &__label {
      @include g.font-size(10);
      line-height: 1;
    }

    &__date {
      @include g.font-size(12);
      margin-top: 15px;
      line-height: 1;

      @include g.mq(md) {
        @include g.font-size(10);
        margin-top: 10px;
      }
    }
  }
}

.p-company-history {
  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(lg) {
      display: block;
    }
  }

  &-item {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    border-bottom: 1px solid #dfdfdf;

    @include g.mq(md) {
      padding: 20px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &-list {
    display: flex;
    align-items: baseline;
    width: 100%;

    @include g.mq(md) {
      display: block;
    }

    &-head {}

    &__year {
      display: block;
      color: g.$color-title;
      font-weight: bold;
      line-height: 1.8;
      text-align: center;
      width: 160px;
      @include g.font-size(20);

      @include g.mq(md) {
        @include g.font-size(18);
        width: 100%;
        text-align: left;
      }
    }

    &-body {
      width: 100%;

      @include g.mq(md) {
        padding: 0;
        width: 100%;
      }

      &__item {
        position: relative;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        line-height: 1.8;
        width: 100%;
        border-left: 2px solid g.$color-title;
        padding: 20px;

        @include g.mq(md) {
          padding: 5px 0 0 0;
          border-left: none;
        }

        &:before {
          content: "";
          position: absolute;
          left: -7px;
          top: 28px;
          display: block;
          background: g.$color-title;
          width: 13px;
          height: 13px;
          border-radius: 50%;

          @include g.mq(md) {
            display: none;
          }

        }

        &:first-child {
          padding-top: 40px;

          &::before {
            top: 48px;
          }

          @include g.mq(md) {
            padding-top: 0;
          }

        }

        &:last-child {
          padding-bottom: 40px;

          @include g.mq(md) {
            padding-bottom: 0;
          }
        }

        &-first {
          &:first-child {
            padding-top: 0;

            &::before {
              top: 0px;
            }
          }
        }

      }
    }

    &__days {
      position: relative;
      box-sizing: border-box;
      line-height: 1.8;
      flex: 0 0 100px;
      padding: 0 30px;

      @include g.mq(md) {
        flex: 0 0 40px;
        padding: 0;
      }

    }

    &__detail {
      flex: 1 1 0;
      box-sizing: border-box;
      line-height: 1.8;
    }
  }
}