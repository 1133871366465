@use "../../../global" as g;

.p-privacy {
  &__intro {
    padding: 100px 15px;

    @include g.mq(md) {
      padding: 60px 15px 0;
    }
  }

  &__inner {
    max-width: 1220px;
    margin: 0 auto;
    padding: 39px 40px 39px 70px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 1px 2px 15px 15px rgba(234, 224, 220, .2);

    @include g.mq(md) {
      max-width: unset;
      padding: 20px 25px;
    }
  }

  &__heading {
    margin-bottom: 19px;
    color: g.$color-title;
    font-weight: bold;
    line-height: 1.5;

    @include g.font-size(24);

    @include g.mq(md) {
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &__date {
    text-align: right;
    margin-bottom: 5px;
    @include g.font-size(13);

    @include g.mq(md) {
      @include g.font-size(12);
    }
  }
}


.p-privacy-content {
  padding: 0 15px 100px;

  @include g.mq(md) {
    padding: 40px 15px;
  }

  &__inner {
    max-width: 1220px;
    padding: 0;
    margin: 0 auto;
  }

  &__title {
    color: g.$color-title;
    font-weight: bold;

    @include g.font-size(18);

    @include g.mq(md) {
      @include g.font-size(16);

      margin-bottom: 10px;
    }
  }

  &__text {
    margin-bottom: 47px;

    &:last-child {
      margin-bottom: 0px;
    }

    @include g.mq(md) {
      margin-bottom: 21px;
      line-height: calc(22 / 12);
    }

  }

  &__address {
    @include g.font-size(14);
    text-align: right;

    @include g.mq(md) {
      @include g.font-size(12);
    }
  }

  &__list {
    margin-left: 20px;
  }

  &__item {
    padding-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

}