@use "../../global" as g;

.c-list-offer {
  padding: 50px 15px 30px;
  background-color: g.$color-secondary;

  @include g.mq(md) {
    padding: 46px 30px 12px;
  }

  &--modify {
    padding-bottom: 103px;

    @include g.mq(md) {
      padding-bottom: 67px;
    }
  }

  &__inner {
    max-width: 1084px;
    margin: 0 auto;
  }

  &__list {
    padding-left: 0;
    list-style-type: none;

    @include g.mq(md) {
      margin: 0 5px;
    }
  }

  &__content {
    flex: 1;
    padding: 8px 120px 0 84px;

    @include g.mq(xl) {
      padding-right: 30px;
    }

    @include g.mq(lg) {
      width: 100%;
      margin-bottom: 7px;
      padding: 0;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 38px;

    &:last-child {
      margin-bottom: 0;
    }


    &--reverse {
      flex-direction: row-reverse;
      padding-left: 72px;

      @include g.mq(lg) {
        padding: 0;
      }
    }

    &--reverse .c-list-offer__content  {
      padding-right: 0;
      padding-left: 115px;

      @include g.mq(xl) {
        padding-left: 30px;
      }

      @include g.mq(lg) {
        padding: 0;
      }
    }

    @include g.mq(lg) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 500px;
      margin: 0 auto 59px;
    }
  }

  &__image {
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    margin-top: 7px;

    @include g.mq(md)  {
      position: relative;
      width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: -17px;
      left: -22px;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      opacity: .3;
      background-color: g.$color-bg;

      @include g.mq(md)  {
        display: none;
      }
    }

    &-item {
      width: 472px;
      height: 300px;
      overflow: hidden;
      border-radius: 3px;

      @include g.mq(md)  {
        position: relative;
        width: 100%;
        height: unset;
        padding-top: 63.88888888888889%;
      }
    }

    &-item img {
      @include g.mq(md)  {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__detail {
    line-height: calc(32 / 16);

    @include g.font-size(16);

    @include g.mq(md) {
      line-height: calc(22 / 12);

      @include g.font-size(12);
    }
  }
}

.c-list-offer--flex {
  padding-bottom: 152px;

  @include g.mq(md) {
    padding-bottom: 71px;
  }
}
