@use "../global" as g;

.l-header {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    margin: 0 auto;
    padding: 0 70px 0 32px;

    @include g.mq(md) {
      height: 69px;
      padding: 0 29px 0 19px;
    }
  }


  &__logo {
    position: relative;
    top: -2px;
    width: 210px;

    @include g.mq(md) {
      top: -4px;

      img {
        width: 100%;
        max-width: 180px;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__menu-list {
    display: flex;
    align-items: center;
    margin-top: -4px;
    list-style: none;
  }

  &__menu-item {
    margin-right: 17.5px;
  }

  &__menu-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20px;
    padding: 0 23px;
    /*    border: 1px solid g.$color-black;*/
    color: g.$color-black;
    font-family: "Noto Sans JP", sans-serif;
    line-height: 1;

    @include g.font-size(14);

    .is-tablet & {
      padding-top: 1px;
      padding-bottom: 2px;
    }
  }

  &__mail {
    display: block;
    position: relative;
    top: -2px;
    width: 48px;
    margin-right: 24px;
  }

  &__btn-menu {
    display: inline-block;
    position: fixed;
    z-index: 1000;
    top: 36px;
    right: 31px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    @include g.mq(md) {
      top: 16px;
      right: 25px;
    }

    &--fixed span {
      border-color: g.$color-text !important;
    }

    span {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -19px;
      transform-origin: 50% 50%;
      transition: all 200ms ease;
      background: g.$color-black;
      height: 1px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 1);

      @include g.mq(md) {
        margin-left: -17px;
      }

      &:nth-child(1) {
        width: 38px;
        margin-top: -11px;
      }

      &:nth-child(2) {
        width: 27px;
        margin-top: 1px;
      }

      &:nth-child(3) {
        width: 20px;
        margin-top: 13px;
      }
    }

    &:hover span {
      &:nth-child(1) {
        animation: 500ms ease-in-out -500ms infinite alternate both hamburger-toggler-hover;
      }

      &:nth-child(2) {
        animation: 500ms ease-in-out -250ms infinite alternate both hamburger-toggler-hover;
      }

      &:nth-child(3) {
        animation: 500ms ease-in-out 0ms infinite alternate both hamburger-toggler-hover;
      }
    }

    &.is-active {
      span {
        margin-top: 0;
        transform: none;
        border-color: #fff !important;
        box-shadow: none;
        background: #fff;


        &:nth-child(1) {
          width: 40px;
          transform: rotate(-45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          width: 40px;
          transform: rotate(45deg);
        }
      }
    }

    &.is-active:hover span {
      &:nth-child(1) {
        animation: none;
      }

      &:nth-child(2) {
        animation: none;
      }

      &:nth-child(3) {
        animation: none;
      }
    }
  }
}

.l-header--top {
  background: transparent;

  .l-header {
    &__logo {
      top: 0;
    }

    &__inner {
      height: 100px;

      @include g.mq(md) {
        height: 76px;
      }
    }

    &__menu-link {
      /*      border: 1px solid #fff;*/
      /*      color: #fff;*/
    }

    &__btn-menu {
      top: 27px;

      @include g.mq(md) {
        top: 16px;
        right: 31px;
      }

      span {
        border-color: g.$color-black;
      }
    }
  }
}

.l-header--no-keyvisual {
  position: relative;
  height: 208px;

  @include g.mq(md) {
    height: 133px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 96px;
    background: url("../images/common/bg_header_decor.png") no-repeat 0 0 / 100% 100%;

    @include g.mq(md) {
      height: 54px;
      background: url("../images/common/sp/bg_header_decor.png") no-repeat 0 0 / 100% 100%;
    }
  }
}

.l-navigation {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgb(70, 106, 126), rgb(63, 42, 52));
  color: #fff;

  a {
    color: #fff;
  }

  &__container {
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include g.mq(md) {
      display: block;
      overflow-y: auto;
    }
  }

  &__inner {
    padding: 6vw 5vw;
  }

  &__main {

    display: flex;
    margin-top: 30px;
    column-gap: 14vw;

    @include g.mq(md) {
      flex-direction: column;
      row-gap: 30px;
      max-width: unset;
    }
  }

  &__logo {
    display: inline-block;
    position: relative;
    z-index: 2;

    @include g.mq(md) {
      width: 200px;
      margin-bottom: 15px;
    }
  }

  &__related,
  &__nav {
    @include g.mq(md) {
      width: 100% !important;
    }
  }

  &__related {
    position: relative;

    &::after {
      position: absolute;
      content: "";
      right: -7vw;
      top: 0;
      bottom: 0;
      background-color: #fff;
      width: 1px;

      @include g.mq(md) {
        content: none;
      }
    }

    @include g.mq(md) {
      order: 1;
    }
  }

  &__nav {
    @include g.mq(md) {
      padding: 30px 0;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      order: 0;
    }
  }





  &__heading {
    margin-bottom: 2.083vw;
    padding-left: 0.556vw;
    font-weight: 400;
    line-height: calc(46 / 40);

    @include g.font-size(40);

    @include g.mq(xl) {
      padding-left: 0;

      @include g.font-size(30);
    }

    @include g.mq(md) {
      @include g.font-size(32);

      margin-bottom: 27px;
      line-height: 1;
    }
  }

  &__social {
    display: flex;
    margin-bottom: 3.75vw;

    @include g.mq(md) {
      margin-bottom: -2px;
    }
  }

  &__social-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: 11px;
    margin-left: 0.556vw;
    list-style: none;

    @include g.mq(xl) {
      margin-left: 0;
    }

    @include g.mq(md) {
      margin: 0 2.326vw 0 0.698vw;
    }
  }

  &__social-item {
    margin-right: 0.694vw;
    margin-bottom: 0.694vw;

    &:last-child {
      margin-right: 0;
    }

    @include g.mq(md) {
      margin-right: 2.326vw;
      margin-bottom: 2.326vw;
    }
  }

  &__social-link {
    @include g.font-size(10);

    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 20px;
    padding: 0 1.214vw;
    border: 1px solid #fff;
    line-height: 1;

    .is-tablet & {
      padding-top: 1px;
      padding-bottom: 2px;
    }

    @include g.mq(md) {
      min-height: 20px;
      padding: 0 23px;
      line-height: 1;
    }
  }

  &__social-instagram {
    width: 18px;
    height: 18px;
    margin-top: 0.139vw;

    img {
      vertical-align: top;
    }

    @include g.mq(md) {
      margin-top: 1px;
    }
  }

  &__order-list {
    margin-bottom: 4.25vw;
    margin-left: 4px;
    padding: 0;
    list-style: none;

    @include g.mq(md) {
      display: flex;
      margin-bottom: 0;
      margin-left: 0.93vw;
    }
  }

  &__order-item {
    margin-bottom: 0.903vw;

    @include g.mq(md) {
      &:nth-child(even) {
        margin-right: 0;
      }

      min-height: 46px;
      margin-right: 2.791vw;
      margin-bottom: 2.791vw;

      &:nth-child(1) {
        width: 34.884vw;
      }

      &:nth-child(2) {
        width: 41.395vw;
      }
    }
  }

  &__order-link {
    margin-left: 10px;
    display: block;
    line-height: 2;

    @include g.mq(xl) {
      margin-left: 0;
    }

    @include g.mq(md) {
      /*      width: 54.5%;*/
      width: 45%;
      line-height: unset;
    }

    @media (max-width: 390px) {
      width: 100%;
    }

    &-title {
      margin-left: 10px;
      @include g.font-size(14);
      margin-top: 12px;

      @include g.mq(xl) {
        margin-left: 0;
      }

      @include g.mq(md) {
        margin-top: 10px;
        padding-left: .208vw;
      }
    }

    &--indent {
      padding-left: 15px;
    }

  }

  &__order-link span {
    @include g.font-size(14);

    position: relative;
    padding-right: 39px;

    @include g.mq(md) {
      padding-right: 0;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      width: 15px;
      height: 15px;
      margin-top: -6px;
      background: url("../images/common/ico_logo_link.svg") no-repeat center / auto 100%;

      .is-chromium & {
        margin-top: -8px;
      }

      @include g.mq(md) {
        right: -20px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
      }
    }
  }

  &__nav-list {
    list-style: none;

    &-item {
      &:first-child {
        margin-top: 0;
      }

      margin-top:20px;
    }

    &-title {
      display: block;
      @include g.font-size(18);

      @include g.mq(md) {
        @include g.font-size(16);
      }

    }

    &-subtitle {
      @include g.font-size(12);
      display: block;
    }

  }

  &__nav-item {

    &:last-child {
      margin-bottom: 0;
    }

    @include g.mq(xl) {
      display: block;
    }

    @include g.mq(md) {
      position: relative;
      margin-bottom: 22px;
    }



  }


  &__nav-heading {
    width: 15.556vw;
    padding-right: 1.042vw;
    line-height: 1.2;

    .is-tablet & {
      width: 16vw;
    }

    @include g.mq(xl) {
      width: 100%;
      margin-bottom: 1.042vw;
      padding-right: 20px;
    }

    @include g.mq(md) {
      margin-bottom: 2px;
    }

    &-large {
      @include g.font-size(18);
      display: block;
    }

    &-small {
      @include g.font-size(9);
    }

    &--link {
      display: inline-block;
      width: auto;
    }
  }

  /*
  &__nav-sub-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 30px;
    justify-content: center;    
    list-style: none;

    @include g.mq(xl) {
      grid-template-columns: repeat(2, 1fr);    
      row-gap: 10px;
      column-gap: 20px;
    }

    @include g.mq(md) {
      display:block;
    }
  }
  */

  /*
  &__nav-sub-item {
    position: relative;
    margin-bottom: 3px;
    line-height: 1.5;
    padding: 0 5px;

    @include g.font-size(14);

    padding-top: 10px;

    @include g.mq(md) {
      width: 100%;
      padding:20px 0;
      border-bottom: 1px solid #c1c6cb;
      line-height: 1;
      &:first-child{
        border-top: 1px solid #c1c6cb;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  */

  &__nav-sub-title {
    @include g.mq(md) {
      display: inline-block;
      width: 80px;
    }
  }

  &__nav-sub-subtitle {
    @include g.font-size(10);
    display: none;

    @include g.mq(md) {
      display: inline-block;
    }
  }

  &__company {
    @include g.font-size(14);

    @include g.mq(md) {
      @include g.font-size(12);
    }
  }

  &__tel {
    @include g.font-size(14);

    @include g.mq(md) {
      @include g.font-size(12);
    }
  }

  &__phone {
    margin-left: 10px;
    @include g.font-size(14);

    @include g.mq(md) {
      @include g.font-size(12);
    }
  }

  &__address {
    font-style: normal;
    @include g.font-size(14);

    @include g.mq(md) {
      @include g.font-size(12);
    }

  }

  &__work {
    margin-bottom: 8.681vw;

    @include g.font-size(12);

    line-height: calc(20 / 12);

    @include g.mq(md) {
      @include g.font-size(10);

      margin-bottom: 3.721vw;
      margin-left: 2.326vw;
      line-height: calc(14 / 10);
    }
  }

  &__privacy-list,
  &__other-list {
    list-style: none;
    margin-top: 20px;
    @include g.font-size(12);

    @include g.mq(md) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.93vw;
    }

    &-item {
      margin-bottom: 4px;

      @include g.mq(md) {
        margin-bottom: 1px;

        &:nth-child(odd) {
          /*        width: 45.5%;*/
          width: 45%;
        }

        &:nth-child(even) {
          width: 54%;
        }
      }

      @media (max-width: 390px) {
        width: 100% !important;
      }
    }
  }

  &__other-list {
    margin-top: 0;
  }

  &__other-title {
    @include g.font-size(12);
    margin-top: 20px;
  }
}