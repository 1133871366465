@use "../../global" as g;

.ez-toc-list,
.ez-toc-list-level-3 {
  list-style: none;
}

.ez-toc-heading-level-2 {
	line-height:2.5;
}

.ez-toc-list {
/*  display: none;*/
  padding: 12px 43px 26px;
  background: rgba(185,163,136,.2);

  @include g.mq(md) {
    padding: 19px;
  }
}

.ez-toc-title {
  display: flex;
  position: relative;
  align-items: center;
  min-height: 54px;
  padding: 0 70px 0 50px;
  background: rgba(185,163,136,.3);
  color: g.$color-title;
  cursor: pointer;

  @include g.mq(md) {
    @include g.font-size(16);

    min-height: 43px;
    padding: 0 35px 0 19px;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 39px;
    width: 8px;
    height: 8px;
    transform: translateY(-50%) rotate(-45deg);
    transition: transform .3s ease;
    border: 1px solid g.$color-title;
    border-top: 0;
    border-right: 0;

    @include g.mq(md) {
      right: 30px;
    }
  }

  @include g.font-size(24);
}

.ez-toc-link {
  transition: .3s ease;
  color: #4384c6;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 2px;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

}

.p-column-detail__table.has-sub.is-active {
  .ez-toc-title::after {
    transform: translateY(-50%) rotate(135deg);
  }
}
