@use "../../global" as g;

.c-contact {
  height: 596px;
  padding: 130px 15px;
  background: url("../images/common/bg_contact.png") no-repeat 0 0 / cover;

  @include g.mq(md) {
    height: unset;
    padding: 15px 15px 2px;
    background: url("../images/common/sp/bg_contact.jpg") no-repeat 0 0 / cover;
  }

  &__inner {
    max-width: 890px;
    margin: 0 auto;

    @include g.mq(md) {
      max-width: unset;
    }
  }

  &__intro {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 51px;
    color: #fff;

    @include g.mq(md) {
      display: block;
      margin-bottom: 18px;
      text-align: center;
    }
  }

  &__heading {
    position: relative;
    top: -7px;
    left: 56px;
    flex-shrink: 0;
    width: 330px;
    font-weight: 400;

    @include g.font-size(36);

    @include g.mq(md) {
      top: 0;
      left: 0;
      width: unset;

      @include g.font-size(20);
    }

    &::after {
      content: "Contact";
      position: absolute;
      top: -110px;
      left: -15.972vw;
      opacity: 0.2;
      color: #fff;
      letter-spacing: .1em;
      pointer-events: none;


      @include g.font-size(128);

      @include g.mq(xl) {
        left: -40px
      }

      @include g.mq(md) {
        @include g.font-size(48);

        top: -32px;
        left: 0;
      }
    }
  }

  &__text {
    @include g.font-size(24);

    max-width: 510px;
    line-height: 1.5;

    @include g.mq(md) {
      max-width: unset;

      @include g.font-size(13);

      line-height: calc(16 / 13);
    }
  }

  &__inquiry {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    align-items: center;
    justify-content:  center;
    max-width: 660px;
    margin: 0 auto;
    padding: 29px 30px 23px;
    border: 1px solid #fff;

    @include g.mq(md) {
      display: block;
      max-width: unset;
      min-height: unset;
      padding: 0;
      border: none;
    }
/*
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: -53px;
      width: 14px;
      height: 6px;
      margin-top: 8px;
      background: url("../images/common/ico_arrow_01_white.svg") no-repeat 0 0 /auto 100%;

      @include g.mq(lg) {
        left: -30px;
      }

      @include g.mq(md) {
        display: none;
      }
    }
*/
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 578px;
    margin: 0 auto 13px;
    list-style: none;

    @include g.mq(md) {
      display: block;
      width: unset;
    }
  }

  &__item {
    width: calc((100% - 80px) / 3);
    min-height: 46px;
    margin: 0 40px 10px 0;
    border: 1px solid #fff;
    border-radius: 26px;

    &:nth-child(3n) {
      margin-right: 0;

      @include g.mq(md) {
        margin-right: auto;
      }
    }

    @include g.mq(md) {
      width: 320px;
      min-height: 46px;
      margin: 0 auto 16px;
    }
  }

  &__link {
    display: flex;

    @include g.font-size(14);

    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    color: #fff;
    text-align: center;
  }

  &__time {
    color: #fff;
    text-align: right;

    @include g.mq(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 13px;
      text-align: center;
    }
  }

  &__work {
    @include g.font-size(14);

    position: relative;
    line-height: 1;
    text-align: center;

    @include g.mq(md) {
      top: 2px;
      text-align: unset;
    }
  }

  &__tel {
    @include g.font-size(25);

    color: #fff;
    line-height: 1;

    @include g.mq(md) {
      @include g.font-size(24);

      margin-right: 13px;
    }
  }
}
