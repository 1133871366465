@use "../../global" as g;

.c-pagination-primary {
  .wp-pager {
    display: flex;
    align-items: center;
    list-style: none;

    & > li {
      margin-right: 6px;
    }
  }

  .page-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 2px;
    transition: all .26s ease;
    border: 1px solid g.$color-tertiary;
    color: g.$color-tertiary;

    @include g.font-size(20);

    @include g.mq(md) {
      .is-ios & {
        padding-bottom: 4px;
      }
    }

    &:hover{
      border-color: #cebc99;
      opacity: 1;
      background: #cebc99;
      color: #fff;
    }

    &.current {
      border-color: #cebc99;
      background: #cebc99;
      color: #fff;
    }
  }
}

.c-pagination-secondary {
  margin-top: 82px;

  @include g.mq(md) {
    margin-top: 44px;
    padding-top: 25px;
  }

  &__inner {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 74px 0 54px;

    @include g.mq(md) {
      margin: 0 76px 0 54px;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 3;
    max-width: 370px;
    height: 53px;
    margin: 0 auto;

    &::after,
    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: url("../images/common/line_pagination_decor.png") no-repeat 0 0 / auto 100%;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  &__arrow {
    position: absolute;
    color: g.$color-title;

    @include g.font-size(14);

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 14px;
      height: 6px;
      margin-top: -3px;
      transition: .3s ease;
      background: url("../images/common/ico_pagination_arrow.svg") no-repeat 0 0 / auto 100%;
    }

    &--prev::after {
      right: -39px;
      transform: scaleX(-1);
    }


    &--next::after {
      left: -39px;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &__arrow--prev:hover::after {
    transform: scaleX(-1) translateX(5px);
  }

  &__arrow--next:hover::after {
    transform: translateX(5px);
  }

  &--detail {
    @include g.mq(md) {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -100vw;
        bottom: 0;
        left: -100vw;
        background: g.$color-secondary;
        pointer-events: none;
      }
    }
  }
}

