@use "../../../global" as g;

.p-orangeribbon-message {

  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(lg) {
      display: block;
    }
  }

  &__btn {
    margin-top: 30px;
  }

}