@use "../../../global" as g;


.p-top-keyvisual {
  position: relative;
  margin-top: 100px;

  &__content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);

    @include g.mq(md) {
      width: 100%;
      margin: 0 auto;
    }
  }

  &__heading {
    /*    color: #fff;*/
    /*    font-weight: bold;*/
    /*    text-align: center;*/

    &-title {
      @include g.font-size(40);

      margin-top: 30px;
      line-height: 2;

      &--bg {
        background-color: #fff;
      }

      &--shadow {
        text-shadow: 0 2px 2px rgba(255, 255, 255, .8), 0 2px 2px rgba(255, 255, 255, .8);
      }

      &--eng {
        @include g.font-size(24);
        color: g.$color-title;

        @include g.mq(md) {
          @include g.font-size(26);
        }
      }

      @include g.mq(md) {
        margin-top: 20px;
        line-height: 1.8;
        @include g.font-size(16);
      }
    }
  }

  &__img {
    width: 75%;
    margin-left: auto;
    padding-right: 20px;

    img {
      width: 75%;
      border-radius: 20px;
    }

    @include g.mq(md) {
      width: 80%;
      padding-right: 15px;

      img {
        width: 80%;
        border-radius: 10px;
      }
    }
  }
}

.p-top-pickup {
  margin-top: 80px;
  padding: 0 15px;

  @include g.mq(md) {
    margin-top: 35px;
    padding: 0 15px;
  }

  &__wrapper {
    max-width: 840px;
    margin: 0 auto;
    @include g.mq(md) {
      text-align:left;
    }
  }

  &__link{
    @include g.font-size(20);
    display:inline-block;
    align-items: center;
    line-height:1.5;
    margin: 0 auto;
    position: relative;

    &:after {
      content: '';
      display: inline-block;
      background-image: url('../images/common/ico_arrow.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 25px;
      height: 15px;
      vertical-align: middle;
      margin-left: 5px;
    }

    @include g.mq(md) {
      @include g.font-size(14);
      &:after {
        width: 20px;
        height: 13px;
      }
    }

    &:hover {
      &:after {
        margin-left: 10px;
      }
    }

  }

}

.p-top-video {

  margin-top: 80px;
  padding: 0 15px;

  @include g.mq(md) {
    margin-top: 30px;
/*  padding: 0 30px;*/
    padding: 0 15px;
  }

  &__wrapper {
    max-width: 1260px;
    margin: 0 auto;
  }

  &__content {
    @include g.mq(md) {
	width:80%;
        margin:0 auto;
    }
  }

  &__title {
    position:relative;
    padding-bottom: 30px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 1px;
      background-color: #393327;
      z-index: 999;
    }
  }

  &__heading-secondary {
    margin-bottom: 40px;
  }

  &__heading {
    text-align: center;
    margin-bottom: 40px;
  }

  &__youtube {
    display: flex;
    justify-content: center;
  }

  &__list {
    display: flex;
    justify-content: flex-start;
    column-gap: 60px;
    row-gap: 40px;
    list-style: none;
    flex-wrap: wrap;
    max-width: 1060px;

    iframe {
      width: 100%;
      height: 200px;
    }

    @include g.mq(xl) {
      max-width: 960px;
    }

    @include g.mq(md) {
      flex-flow: column;
      max-width: 400px;
      column-gap: 0;
      row-gap: 40px;
      width:100%;      

      /*
      iframe {
        height: 140px;
      }
      */
    }

    &-item {
      flex: 1 1 calc(100% / 3);
      max-width: calc((100% / 3) - 40px);

      @include g.mq(md) {
	flex: 0 1 auto;
        max-width: 100%;
/*
        flex: 1 1 50%;
        max-width: calc(50% - 10px);
*/
      }
      &-title {
	line-height:1.5em;
	@include g.mq(md) {
          text-align:center;
        }
      }
    }
  }
}

.p-top-management,
.p-top-business {
  margin-top: 100px;
  padding: 0px 15px;

  @include g.mq(md) {
    margin-top: 50px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1260px;
    margin: 0 auto;

    @include g.mq(lg) {
      flex-direction: column;
    }
  }

  &__image {
    width: 60%;
    z-index: 99;

    @include g.mq(lg) {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__image-item {
    border-radius: 6px;

    @include g.mq(md) {
      border-radius: 4px;
    }
  }

  &__content {
    margin-top: 50px;
    width: 70%;
    border-radius: 3px;
    background: #fff;

    @include g.mq(lg) {
      margin-top: -30px;
      width: 100%;
    }

    &-wrapper {
      padding: 30px 60px;

      @include g.mq(md) {
        padding: 30px 0;
      }
    }

    @include g.mq(md) {
      width: 100%;
    }
  }

  &__heading {
    margin-bottom: 30px;
    line-height: 1.4;

    @include g.font-size(26);

    @include g.mq(md) {
      margin-bottom: 25px;
      @include g.font-size(20);
    }
  }

  &__text {
    padding-left: 5px;
  }

  &__title-wrapper {
    padding: 40px 80px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
    }

    @include g.mq(md) {
      padding: 60px 15px 40px 15px;
    }

  }

  &__title {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 80px;
      width: 80px;
      height: 1px;
      background-color: g.$color-title;
      z-index: 999;

      @include g.mq(md) {
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-main {
      display: block;
      line-height: 1;
      letter-spacing: -3px;
      @include g.font-size(60);

      @include g.mq(md) {
        text-align: center;
        font-weight: bold;
        letter-spacing: 0;
        @include g.font-size(30);
      }
    }

    &-sub {
      display: block;
      color: g.$color-title;
      @include g.font-size(18);

      @include g.mq(md) {
        @include g.font-size(16);
        text-align: center;
      }
    }
  }

  &__btn {
    margin-top: 30px;
  }

}

.p-top-business {
  &__image {
    @include g.mq(md) {
      order: 0;
    }
  }

  &__content {
    @include g.mq(md) {
      order: 1;
    }
  }
}

.p-top-service {
  margin-top: 100px;

  &__group {
    position: relative;
  }

  &__content {
    display: flex;
    position: relative;
    align-items: flex-start;
    max-width: 1060px;
    min-height: 200px;
    margin: -100px auto 50px;
    padding: 60px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 1px 2px 15px 15px rgba(233, 233, 233, 0.2);

    @include g.mq(md) {
      margin: -50px 20px 0;
      padding: 40px 20px;
    }

    /*
    @include g.mq(lg) {
      width: auto;
      margin: -60px 30px 0;
    }

    @include g.mq(md) {
      margin: -50px 15px 0;
      padding:40px 30px;
    }
*/

  }

  &__image {
    position: relative;

    @include g.mq(md) {
      height: 290px;
    }

  }

  &__detail {
    flex: 1;

    @include g.mq(md) {
      padding-left: 0;
    }
  }

  &__title {
    position: relative;
    padding-bottom: 50px;

    @include g.mq(md) {
      padding-bottom: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 1px;
      background-color: g.$color-title;
      z-index: 999;

      @include g.mq(md) {
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-main {
      display: block;
      line-height: 1;
      margin-top: 30px;
      @include g.font-size(30);

      @include g.mq(md) {
        margin-top: 10px;
        @include g.font-size(24);
        text-align: center;
        letter-spacing: 0;
        line-height: 1.4;
      }
    }

    &-sub {
      display: block;
      color: g.$color-title;
      @include g.font-size(14);

      @include g.mq(md) {
        text-align: center;
      }
    }
  }

  &__text {
    margin-top: 30px;
  }

  &__btn {
    margin-top: 30px;
  }

}

.p-top-information {
  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(md) {
      display: block;
    }
  }

  &__news {
    flex: 1;

    @include g.mq(md) {
      margin-top: 40px;
    }
  }

  &__list {
    margin-bottom: 40px;
    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @include g.mq(md) {
      display: block;
      @include g.font-size(14);
      margin-bottom: 20px;
    }
  }

  &__time {
    align-self: flex-start;
    width: 116px;

    @include g.mq(md) {
      display: block;
      width: auto;
    }
  }

  &__link {
    flex: 1;
  }

  &__title {
    width: 200px;
    position: relative;
    padding-bottom: 30px;

    @include g.mq(md) {
      text-align: center;
      margin: 0 auto;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 1px;
      background-color: g.$color-title;
      z-index: 999;

      @include g.mq(md) {
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-main {
      display: block;
      line-height: 1;
      margin-top: 10px;
      @include g.font-size(30);
    }

    &-sub {
      display: block;
      color: g.$color-title;
      @include g.font-size(14);
    }
  }

}