@use "../../global" as g;

.c-author {
  padding: 27px 15px 77px;

  @include g.mq(md) {
    padding: 38px 15px 49px;
  }

  .c-heading-secondary {
    margin-bottom: 102px;

    @include g.mq(md) {
      margin-bottom: 53px;
    }
  }

  &__slider {
    max-width: 1100px;
    margin: 0 auto;

    @include g.mq(md) {
      max-width: unset;
    }
  }

  &__image {
    position: relative;
    margin-bottom: 19px;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include g.mq(md) {
      width: 80px;
      height: 80px;
      margin: 0 auto 7px;
      padding-top: unset;
    }
  }

  &__name {
    margin-bottom: 10px;
    color: g.$color-title;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;

    @include g.font-size(24);

    @include g.mq(md) {
      @include g.font-size(12);
      margin-bottom: 5px;
    }
  }

  &__text {
    @include g.font-size(12);

/*    margin-right: -30px;*/
    line-height: calc(18 / 12);
	text-align:center;

    @include g.mq(md) {
      @include g.font-size(10);

      margin-right: 0;
      padding-left: 10px;
      line-height: calc(12 / 10);
    }
  }
}
