@use "../../global" as g;

.c-list-information {
  max-width: 1220px;
  margin: 0 auto;

  @include g.mq(md) {
    max-width: unset;
    margin: 0 30px;
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 auto 18px;
    padding: 10px 0;
    border: 1px solid #B09B86;
    border-radius: 3px;
    background-color: #fff;

    &:last-child {
      margin-bottom: 0;
    }

    @include g.mq(md) {
      flex-direction: column;
      margin-bottom: 13px;
      padding: 15px 15px 14px;
    }
  }

  &-number {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 433px;
    padding-left: 40px;
    color: g.$color-title;

    @include g.mq(xl) {
      flex-wrap: wrap;
      width: 265px;
    }

    @include g.mq(md) {
      flex-wrap: nowrap;
      width: 100%;
      margin-top: -10px;
      margin-bottom: 8px;
      padding-left: 0;
    }

    span {
      display: inline-block;
      position: relative;
    }
  }

  &-point {
    @include g.font-size(20);

    top: 1px;
    margin-right: 21px;

    @include g.mq(md) {
      top: 0;
      margin-right: 18px;
    }
  }

  &-order {
    @include g.font-size(48);

    top: -8px;
    margin-right: 14px;

    @include g.mq(md) {
      @include g.font-size(32);

      top: -2px;
      margin-right: 11px;
      line-height: 1
    }
  }

  &-type {
    @include g.font-size(24);

    line-height: calc(28 / 24);

    @include g.mq(md) {
      @include g.font-size(20);

      top: 4px;
    }
  }

  &-content {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    padding: 8px 18px 8px 22px;
    border-left: 1px solid #B09B86;

    @include g.mq(md) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      border: 0;
    }
  }

  &-image {
    width: 256px;

    @include g.mq(lg) {
      width: 200px;
    }

    @include g.mq(md) {
      position: relative;
      width: 100%;
      margin-bottom: 7px;
      padding-top: 42.60355029585799%;
    }

    img {
      @include g.mq(md) {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-detail {
    flex: 1;
    margin-right: 4px;
    margin-bottom: 0;
    padding-right: 26px;
    line-height: calc(28 / 16);

    @include g.mq(lg) {
      padding-right: 10px;
    }

    @include g.mq(md) {
      margin-right: 0;
      padding-right: 0;
      line-height: calc(22 / 12);
    }

    span {
      @include g.font-size(14);

      display: inline-block;
	  margin-top: 10px;
	  line-height: calc(20 / 14);
	  &.small{
		@include g.font-size(12);
	  }
    }

    &__notes {
	  margin-top:10px;
      margin-bottom: 5px;
    }
	
  }
}
