@use "../../global" as g;

.c-category {
  display: flex;
  position: relative;
  z-index: 3;
  flex-wrap: wrap;
  margin: 0 -3.5px;
  list-style: none;

  @include g.mq(md) {
    padding-left: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 22px;
    margin: 0 3.5px 10px;
    padding: 2px 11.5px;
    transition: .3s ease;
    border: 1px solid #898080;
    border-radius: 3px;
    color: g.$color-text;
    line-height: 1;

    @include g.font-size(10);

    &:hover {
      border-color: g.$color-black;
    }
  }

  &__link {
    &:hover {
      opacity: 1;
    }
  }
}

.c-category--detail {
  margin: 4px 0 0;

  @include g.mq(md) {
    padding-left: 0;
  }

  .c-category__item {
    margin-right: 5px;
    margin-bottom: 5px;

    @include g.mq(md) {
      margin-left: 0;
    }
  }
}
