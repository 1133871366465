@use "../../../global" as g;

.p-error-breadcrumb {
  margin-top:120px;

  @include g.mq(md) {
	margin-top:69px;
  }
}


.p-section-error {
  padding: 100px 15px;


  @include g.mq(md) {
    padding: 60px 30px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;
  }

  &__title {
    @include g.font-size(28);

    display: block;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 40px;
    color: g.$color-title;
    font-weight: 400;
    line-height: calc(46 / 32);

    @include g.mq(md) {
      @include g.font-size(20);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 42px;
      height: 1px;
      background-color: g.$color-title;
    }
  }
}

.p-error-footer{
   margin-top:140px;
   width:100%;
   @include g.mq(md) {
     margin-top:56px;
   }
   &__copyright{
     padding:20px 0;
     width:100%;
     text-align:center;
     @include g.font-size(10);
     @include g.mq(md) {
       @include g.font-size(8);
     }
   }
}