@use "../../global" as g;

.wp-block-post-content.is-root-container {
  width: calc(748px - 44px - 44px);
  margin: 0 auto;
  padding: 24px 44px 77px;
  background: #fff;

  @include g.mq(xl) {
    width: 100%;
    padding: 0;
  }
}

.block-editor-iframe__body {
  background: #fff;
}

.wp-block.wp-block-post-title {
  @include g.font-size(24);

  margin-bottom: 22px;
  color: #66471f;
  font-weight: bold;

  @include g.mq(md) {
    @include g.font-size(16);

    margin-bottom: 14px;
  }
}

.wp-block-post-content,
.block-editor-block-preview__content-iframe {
  overflow-wrap: break-word;
/*  padding-left: 16px;*/

/*
  @include g.font-size(14);

  line-height: calc(20 / 14);

  @include g.mq(md) {
    @include g.font-size(12);

    padding-left: 0;
    line-height: calc(22 / 12);
  }
*/

  p {
    display: inline-block;
    min-height: 1rem;
    margin-bottom: 20px;

    a {
      text-decoration: underline;
	  color: g.$color-text;
      text-decoration-color: g.$color-text;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;	  
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  img {
    margin-bottom: 1em;
	max-width: 100%;
    height: auto;
  }

  blockquote {
    position: relative;
    margin-bottom: 1.5em;
    padding: 1.5em 2em;
    background: #f8f8f8;

    p {
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &::before,
    &::after {
      position: absolute;
      font-size: 2em;
      line-height: 1;
    }

    &::before {
      content: "“";
      top: .3em;
      left: .3em;
    }

    &::after {
      content: "„";
      right: .3em;
      bottom: .4em;
    }
  }

  ul,
  ol {
    margin-bottom: 1.5em;
    padding-left: 40px;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: g.$color-title;
    font-weight: bold;
  }

  h4,
  h5,
  h6 {
/*    margin-bottom: 1.5em;*/
    font-size: 1em;
    font-weight: bold;
  }

  h2,
  h3 {
    position: relative;
/*    margin-bottom: 10px;*/
/*    padding-left: 39px;*/


    @include g.mq(md) {
/*      margin-top: 44px;*/
/*      margin-bottom: 10px;*/
      padding-bottom: 0;
/*      padding-left: 30px;*/
    }
  }

  h2 {
	margin-top: 20px;
    margin-bottom: 10px;	
    padding-bottom: 7px;
	padding-left: 30px;
    border-bottom: 1px solid g.$color-title;

    @include g.font-size(20);

    @include g.mq(md) {
/*      margin-top: 44px;*/
      margin-top: 10px;
      padding-left: 30px;

      @include g.font-size(16);
    }

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 3px;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: g.$color-title;

      @include g.mq(md) {
        top: 9px;
        width: 16px;
        height: 16px;
      }
    }

  }

  h3 {
    @include g.font-size(18);

/*    margin-top: 10px;*/

    @include g.mq(md) {
/*      margin-top: 44px;*/

      @include g.font-size(14);
    }

/*
    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 3px;
      width: 16px;
      height: 16px;
      background: g.$color-tertiary;

      @include g.mq(md) {
        top: 7px;
        width: 12px;
        height: 12px;
      }
    }
*/

  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  a {
    color: #4384c6;
  }

  .alignleft,
  .alignright,
  .aligncenter {
    display: block;
  }

  .aligncenter,
  .alignright {
    margin-left: auto;
  }

  .alignleft,
  .aligncenter {
    margin-right: auto;
  }

  .alignright {
    margin-right: 0;
    padding: 10px 0 10px 20px;

    @include g.mq(md) {
      display: block;
      padding: 0;
      float: unset;
    }
  }

  .alignleft {
    margin-left: 0;
    padding: 10px 20px 10px 0;

    @include g.mq(md) {
      float: unset;
    }
  }

  table {
    border-width: 1px;
  }

  tbody,
  thead,
  tr,
  th,
  td {
    border-color: inherit;
  }

  th,
  td {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
  }

  figure,
  picture {
    max-width: 100%;
/*	
	margin-top:50px;
    margin-bottom: 30px;
*/
	margin-top:30px;
    margin-bottom:10px;
  }

  ul.is-style-circle,
  ul.is-style-square {
    &>li {
      position: relative;
      color: #423625;

      div {
        display: inline;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        display: inline-block;
        position: relative;
        color: inherit;
        font-size: inherit;
      }
    }
  }

  ul.is-style-circle {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 20px;

      &::before {
        content: "◎";
      }
    }
  }

  ul.is-style-square {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 20px;

      &::before {
        content: "■";
      }
    }
  }
}

.c-post-content {
  @include g.font-size(16);

  color: #303030;
  font-weight: 400;
  line-height: calc(36 / 16);
  overflow-wrap: break-word;

  p,
  ol,
  ul {
    a {
      color: inherit;
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-decoration-color: g.$color-text;
      text-underline-offset: 3px;

      &:hover {
        opacity: 1;
        text-decoration-color: transparent;
      }
    }
  }

  p {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  i,
  em {
    font-style: italic;
  }

  sup,
  sub {
    position: relative;
    font-size: 0.6em;
  }

  sup {
    vertical-align: top;
  }

  sub {
    vertical-align: bottom;
  }

  figure,
  picture,
  img,
  video,
  iframe,
  table {
    max-width: 100%;
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  figure {
    img,
    video,
    iframe,
    table {
      margin-bottom: 0;
    }
  }

  img {
    height: auto;
  }

  figcaption {
    margin: 0.5em 0;
    font-size: 0.8em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    position: relative;
    margin-bottom: 2em;
    padding: 2em;
    background-color: #f8f8f8;

    &::before,
    &::after {
      content: "“";
      position: absolute;
      font-size: 2em;
      line-height: 1;
    }

    &::before {
      top: 0.3em;
      left: 0.3em;
    }

    &::after {
      right: 0.3em;
      bottom: 0.3em;
      transform: scale(-1, -1);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  pre,
  code {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 2em;
    padding-left: 2em;
    list-style: disc;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    margin-bottom: 2em;
    padding-left: 2em;
    list-style: decimal;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h2 {
    margin-bottom: 1em;
    font-size: 1.8em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h3 {
    margin-bottom: 1em;
    font-size: 1.6em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    margin-bottom: 1em;
    font-size: 1.4em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    margin-bottom: 1em;
    font-size: 1.2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h6 {
    margin-bottom: 1em;
    font-size: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &>div {
    margin-bottom: 2em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .is-layout-flex {
    display: flex;
  }

  .has-background {
    background-color: #ccc;
  }

  .has-small-font-size {
    @include g.font-size(13);
  }

  .has-large-font-size {
    @include g.font-size(36);
  }

  .has-x-large-font-size {
    @include g.font-size(42);
  }

  .wp-block-table {
    table {
      border-collapse: collapse;
      border: 1px solid #000;
    }

    thead,
    tbody,
    tfoot {
      border: none;
    }

    th,
    td {
      padding: 0.5em;
      border: 1px solid #000;
    }

    th {
      background-color: #ccc;
      font-weight: bold;
    }
  }

  .wp-block-columns {
    figure {
      margin-bottom: 0.5em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .wp-block-column {
    margin: 0 1em;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .alignleft,
  .alignright,
  .aligncenter {
    display: table;
  }

  .aligncenter,
  .alignright {
    margin-left: auto;
  }

  .alignleft,
  .aligncenter {
    margin-right: auto;
  }

  .alignright {
    margin-right: 0;
  }

  .alignleft {
    margin-left: 0;
  }
}

.c-post-content--news {
  p {
    padding-left: 7px;
  }

  ol {
    padding-left: 1.8em;

    li {
      margin-bottom: .55em;
      padding-left: 1em;

      &:last-child {
        margin-bottom: 0;
      }

      &::marker {
        font-weight: 700;
        vertical-align: top;
      }
    }

    strong {
      &:first-child {
        display: inline-block;
        margin: 0 0 8px -.4em;
      }
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: calc(14 / 16 * 1em);
        left: -1.55em;
        width: calc(11 / 16 * 1em);
        height: calc(11 / 16 * 1em);
        border: 1px solid g.$color-title;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }

  figcaption {
    margin-top: .55em;
    padding: 0 .5em;
    color: #303030;
    font-size: 1em;
  }

  .wp-block-image {
    margin: 4.2em 0 3.15em;
  }

  .wp-block-buttons {
    padding: .25em 1.1em 0;
  }
}
