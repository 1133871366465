@use "../../../global" as g;

.p-section-news {
  padding: 100px 15px;


  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;
  }

  &__title {
    @include g.font-size(32);

    display: block;
    position: relative;
    margin-bottom: 65px;
    padding-bottom: 36px;
    color: g.$color-title;
    font-weight: 400;
    line-height: calc(46 / 32);

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 42px;
      height: 1px;
      background-color: g.$color-title;
    }
  }
}

.p-list-news {

  @include g.font-size(16);

  margin-top: 60px;

  @include g.mq(md) {
    margin-top: 40px;
  }

  line-height: 1.5;

  &__wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    padding: 20px 0;
    border-bottom: 1px solid #9c8c6d;
    line-height: 1.7;

    @include g.mq(md) {
      @include g.font-size(14);
    }

    &:first-child {
      padding: 0 0 20px 0;
    }
  }

  &__item-wrapper {
    display: flex;

    @include g.mq(md) {
      flex-direction: column;
    }
  }

  &__item-date {
    flex: 0 0 auto;
    width: 161px;
    padding: 0 20px 0 7px;

    @include g.mq(md) {
      margin-bottom: 10px;
      padding: 0;
    }
  }

  &__item-label {
    display: -webkit-box;
    padding-right: 3px;
    overflow: hidden;
    transition: text-decoration .5s ease;
    color: #303030;
    text-decoration: underline;
    text-decoration-color: transparent;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;

    @include g.mq(md) {
      padding: 0;
    }

    &:hover {
      opacity: 1;
      text-decoration-color: #303030;
    }
  }
}

.p-article-news {
  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;
  }

  &__title {
    @include g.font-size(22);

    display: block;
    position: relative;
    margin-bottom: 40px;
    padding: 0 0 20px 30px;
    border-bottom: 1px solid g.$color-tertiary;
    color: g.$color-title;
    font-weight: 400;
    line-height: 1.7;

    &::before {
      content: "";
      position: absolute;
      top: 7px;
      left: 0;
      width: 6px;
      height: 28px;
      background-color: g.$color-title;

      @include g.mq(md) {
        top: 1px;
        height: 30px;
      }
    }

    @include g.mq(md) {
      @include g.font-size(18);

      padding: 0 0 20px 30px;
      line-height: 1.7;
    }

  }

  &__image {
    margin-top: 30px;
    max-width: 300px;
  }

  &--link{
    text-decoration:underline;
    &:hover{
      text-decoration:none;
    }
  }

}