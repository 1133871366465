@use "../../global" as g;

.c-btn-primary,
.c-btn-secondary {
  @include g.font-size(14);
  display: flex;
  position: relative;
  align-items: center;
  padding: 5px 30px 5px 52px;
  border: 1px solid g.$color-title;
  border-radius: 5px;
  color: g.$color-title;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 14px;
    height: 6px;
    margin-top: -3px;
    transition: transform .3s ease;
    background: url("../images/common/img_btn_arrow.svg") no-repeat center / auto 100%;
  }

  &:hover::before {
    transform: translateX(5px);
  }

  &--default {
    width: fit-content;
    min-height: 42px;

    @include g.mq(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: 46px;
      padding-right: 10px;
      padding-left: 10px;
    }

    &::before {
      left: 30px;

      @include g.mq(md) {
        position: relative;
        left: 0;
        margin-top: -2px;
        margin-right: 21px;
      }
    }
  }

  &--middle {
    justify-content: center;
    width: 200px;
    min-height: 40px;
    padding: 5px 52px 5px 30px;

    &::before {
      content: none;
    }

    &::after {
      content: "";
      right: 30px;
      position: absolute;
      top: 50%;
      width: 14px;
      height: 6px;
      margin-top: -2px;
      transition: transform .3s ease;
      background: url("../images/common/img_btn_arrow_down.svg") no-repeat center / auto 100%;
    }

    &:hover::after {
      transform: translateY(3px);
    }

  }


  &--large {
    justify-content: center;
    width: 275px;
    min-height: 46px;

    &::before {
      left: 28px;
    }
  }
}

.c-btn-secondary {
  border: 1px solid g.$color-title;
  color: g.$color-title;
  /*
  &::before {
    background: url("../images/common/img_btn_arrow.svg") no-repeat center / auto 100%;
  }
  */
}

.c-btn-primary--default-contact,
.c-btn-secondary--default-contact {
  position: relative;
  left: 15px;
  min-height: 46px;
  margin: 0 auto;

  @include g.mq(md) {
    left: 0;
  }
}

.l-footer {
  .c-btn-primary--large {
    width: 19.097vw;
    padding: 0.347vw 3.472vw;
    border-color: g.$color-primary;
    background: g.$color-bg;
    color: g.$color-primary;

    &::before {
      left: 1.944vw;
      width: 0.972vw;
      height: 0.417vw;
      margin-top: -0.208vw;
      background-image: url("../images/common/ico_arrow_01.svg");
    }
  }
}

.l-header {
  .c-btn-primary--large {
    width: 19.097vw;
    padding: 0.347vw 3.472vw;
    border-color: #fff;
    background: transparent;

    @include g.mq(md) {
      width: 100%;
      height: 100%;
      padding: 0 2.326vw;
      background: #fff;
      color: g.$color-title;
    }

    &::before {
      left: 1.944vw;
      width: 0.972vw;
      height: 0.417vw;
      margin-top: -0.208vw;
      background-image: url("../images/common/ico_arrow_01_white.svg");

      @include g.mq(md) {
        left: 5.349vw;
        width: 3.256vw;
        height: 1.395vw;
        margin-top: -0.698vw;
        background-image: url("../images/common/ico_arrow_01_light.svg");
      }

      @include g.mq(sm) {
        left: 2.326vw;
      }
    }
  }
}

.p-price {
  .c-btn-primary {
    margin: 0 auto;

    @include g.mq(md) {
      width: 283px;
    }
  }
}


.c-btn-cv {
  @include g.font-size(14);
  margin: 0 auto;
  display: block;
  max-width: 200px;
  position: relative;
  align-items: center;
  padding: 13px 30px 13px 30px;
  text-align: center;
  border-radius: 6px;
  background: rgba(156, 140, 109, .8);
  color: #fff;
}