@use "../../global" as g;

.c-breadcrumb {
  position: relative;
  z-index: 2;
  max-width: 1240px;
  margin:0 auto;
  padding: 30px 15px 0;

  @include g.mq(md) {
    padding: 20px 15px 0;
  }

  &--no-keyvisual {
    z-index: 1010;

    @include g.mq(md) {
      transition: all .6s ease;
      background: #fff;
    }
  }

  .c-breadcrumb__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    @include g.mq(md) {
      flex-wrap: unset
    }
  }

  li {
    @include g.font-size(12);

    position: relative;

    &:first-child {
      a {
        text-decoration: underline;
        text-underline-offset: 5px;
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
      padding-right: 50px;

      @include g.mq(md) {
        align-self: center;
        padding-right: 47px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 36px;
        height: 1px;
        background: g.$color-tertiary;
      }
    }
  }
}

.c-breadcrumb--premium .c-breadcrumb__list  li:nth-child(2) {
  @include g.mq(md) {
    min-width: 122px;
  }
}

.c-breadcrumb--column-detail {
  margin-top: 0;
}

.c-breadcrumb--column .c-breadcrumb__list {
  flex-wrap: wrap;
  line-height: 2;
}
