@use "../../global" as g;

.c-introduction {
  padding-top: 11px;

  @include g.mq(md) {
    padding-top: 0;
  }

  &__join {
    position: relative;
    padding-bottom: 155px;
    background-color: g.$color-secondary;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 96px;
      background: url("../images/common/bg_decor_01.png") no-repeat 0 0 / 100% 100%;

      @include g.mq(md) {
        display: none;
      }
    }

    @include g.mq(md) {
      padding: 9px 30px 24px;
    }
  }

  &__inner {
    display: flex;
    position: relative;
    z-index: 2;
    max-width: 1220px;
    margin: 0 auto;
    padding: 58px 95px 55px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 1px 2px 15px 15px rgba(234, 224, 220, 0.2);

    @include g.mq(md) {
      flex-direction: column;
      align-items: center;
      padding: 40px 21px 22px;
    }
  }

  &__content {
    flex-shrink: 1;
    padding-left: 76px;

    @include g.mq(md) {
      padding-left: 0;
    }
  }

  &__text {
    @include g.mq(md) {
      line-height: calc(22 / 12);
    }
  }
}

.c-introduction--no-decor {
  .c-introduction__join {
    &::after {
      display: none;
    }
  }
}
