@use "../../global" as g;

.c-keyvisual {
  position: relative;

  margin-top: 120px;
  padding: 0 15px;

  border-bottom: 1px solid g.$color-title;

  @include g.mq(md) {
    margin-top: 69px;
  }

  &__inner {
    max-width: 1240px;
    margin: 0 auto;
  }

  &__content {
    display: inline-block;
    padding: 80px 15px;
    color: g.$color-title;
    border-bottom: 1px solid g.$color-title;

    @include g.mq(md) {
      padding: 50px 15px;
    }

  }

  &__text {
    margin-bottom: 21px;
    font-weight: 600;
    line-height: calc(26 / 18);

    @include g.font-size(18);
  }

  &__heading {
    line-height: 1;

    &-title {
      @include g.font-size(40);

      display: block;
      line-height: calc(50 / 40);

      @include g.mq(md) {
        @include g.font-size(24);
      }
    }

    &-subtitle {
      @include g.font-size(14);
      display: inline-block;
      margin-top: 30px;
      font-weight: normal;
      line-height: calc(32 / 14);

      @include g.mq(md) {
        margin-top: 10px;
      }
    }

  }
}