@use "../../global" as g;

.u-mbs {
  margin-bottom: 80px;

  @include g.mq(md) {
    margin-bottom: 40px;
  }
}

.u-mb-introduction {
  .c-introduction__join {
    padding-bottom: 45px;

    @include g.mq(md) {
      padding-bottom: 31px;
    }
  }
}
