@use "../global" as g;

html {
  font-size: 100%;
}

body {
  color: g.$color-text;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  font-weight: 400;
  line-height: 2;
/*  font-feature-settings: "palt";*/

  @include g.font-size(16);

  @include g.mq(md) {
    @include g.font-size(14);
  }

  &.has-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;

    .c-breadcrumb--no-keyvisual {
      z-index: 1;
    }
  }
}

a {
  transition: opacity 0.3s ease;
  opacity: 1;
  color: g.$color-text;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

/* Google Font対策 */
p,
h1,
h2,
h3,
h4,
h5 {
  visibility: hidden;
}

p,
h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}


.wf-active {

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    visibility: visible;
  }
}