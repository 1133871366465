@use "../../global" as g;

.c-price-course {
  padding-bottom: 80px;
  background: g.$color-secondary;

  @include g.mq(md) {
    padding-bottom: 64px;
  }

  &__inner {
    position: relative;
    z-index: 2;
    max-width: 1220px;
    margin: 8px auto 0;
    padding: 80px 48px 48px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 1px 2px 15px 15px rgba(234,224,220,.2);

    @include g.mq(md) {
      max-width: unset;
      margin: 0 32px;
      padding: 32px 32px 56px;
    }
  }

  &__block {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    @include g.mq(md) {
      margin-bottom: 56px;
    }
  }

  &__text {
    @include g.mq(md) {
      line-height: calc(22 / 12);
    }
  }

  &__logo {
    width: 60px;
    height: 60px;
    margin: 0 auto 48px;
    overflow: hidden;
    border-radius: 100%;

    @include g.mq(md) {
      margin-bottom: 16px;
    }
  }

  &__list {
    margin-bottom: 24px;
    list-style: none;

    @include g.mq(md) {
      line-height: calc(22 / 12);
    }

    &>li {
      padding-left: 10px;
      text-indent: -10px;
    }
  }

  &__button {
    margin-left: 16px;

    @include g.mq(md) {
      margin: 0;
    }
  }
}


.c-price-course--flex {
  @include g.mq(md) {
    padding-bottom: 24px;
  }
}
