$breakpoints: (
  "sm": "screen and (max-width: 400px)",
  "md": "screen and (max-width: 768px)",
  "lg": "screen and (max-width: 960px)",
  "xl": "screen and (max-width: 1140px)",
  "xxl": "screen and (max-width: 1240px)",
  ) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin font-size($size) {
  font-size: calc($size / 16) * 1rem;
}