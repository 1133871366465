@use "../../global" as g;

.u-sp-only {
  display: none;

  @include g.mq(md) {
    display: block;
  }
}

.u-pc-only {
  display: block;

  @include g.mq(md) {
    display: none;
  }
}
