@use "../../../global" as g;

.p-business-management {
  margin-top: 100px;

  &__group {
    position: relative;
  }

  &__content {
    display: flex;
    position: relative;
    align-items: flex-start;
    max-width: 1060px;
    min-height: 200px;
    margin: -100px auto 50px;
    padding: 60px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 1px 2px 15px 15px rgba(233, 233, 233, 0.2);

    @include g.mq(md) {
      margin: -50px 20px 0;
      padding: 40px 20px;
    }
  }

  &__image {
    position: relative;

    @include g.mq(md) {
      height: 290px;
    }

  }

  &__detail {
    flex: 1;

    @include g.mq(md) {
      padding-left: 0;
    }
  }

  &__title {
    position: relative;
    padding-bottom: 50px;

    @include g.mq(md) {
      padding-bottom: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 1px;
      background-color: g.$color-title;
      z-index: 999;

      @include g.mq(md) {
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-main {
      display: block;
      line-height: 1;
      margin-top: 30px;
      @include g.font-size(30);

      @include g.mq(md) {
        margin-top: 10px;
        @include g.font-size(22);
        text-align: center;
        letter-spacing: 0;
        line-height: 1.4;
      }
    }

    &-sub {
      display: block;
      color: g.$color-title;
      @include g.font-size(14);

      @include g.mq(md) {
        text-align: center;
      }
    }
  }

  &__text {
    margin-top: 30px;
  }

  &__btn {
    margin-top: 30px;
  }

}

.p-business-consulting,
.p-business-healthcare,
.p-business-products {
  margin-top: 100px;
  padding: 0px 15px;

  @include g.mq(md) {
    margin-top: 50px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1260px;
    margin: 0 auto;

    @include g.mq(lg) {
      flex-direction: column;
    }
  }

  &__image {
    width: 60%;
    z-index: 99;

    @include g.mq(lg) {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__image-item {
    border-radius: 6px;

    @include g.mq(md) {
      border-radius: 4px;
    }
  }

  &__content {
    margin-top: 50px;
    width: 70%;
    border-radius: 3px;
    background: #fff;

    @include g.mq(lg) {
      margin-top: -30px;
      width: 100%;
    }

    &-wrapper {
      padding: 30px 60px;

      @include g.mq(md) {
        padding: 30px 0;
      }
    }

    @include g.mq(md) {
      width: 100%;
    }
  }

  &__heading {
    margin-bottom: 30px;
    line-height: 1.4;

    @include g.font-size(26);

    @include g.mq(md) {
      margin-bottom: 25px;
      @include g.font-size(20);
    }
  }

  &__text {
    padding-left: 5px;
  }

  &__title-wrapper {
    padding: 40px 80px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
    }

    @include g.mq(md) {
      padding: 60px 15px 40px 15px;
    }

  }

  &__title {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 80px;
      width: 80px;
      height: 1px;
      background-color: g.$color-title;
      z-index: 999;

      @include g.mq(md) {
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &-main {
      display: block;
      line-height: 1.4;
      @include g.font-size(30);

      @include g.mq(md) {
        text-align: center;
        letter-spacing: 0;
        @include g.font-size(24);
      }
    }

    &-sub {
      display: block;
      color: g.$color-title;
      @include g.font-size(18);

      @include g.mq(md) {
        @include g.font-size(16);
        text-align: center;
      }
    }
  }

  &__btn {
    margin-top: 30px;
  }

}


.p-business-healthcare {
  &__image {
    @include g.mq(md) {
      order: 0;
    }
  }

  &__content {
    @include g.mq(md) {
      order: 1;
    }
  }
}

.p-business-partners {
  margin-top: 100px;
  padding: 100px 15px;
  background: g.$color-secondary;

  @include g.mq(md) {
    margin-top: 50px;
    padding: 60px 15px;
  }

  &__wrapper {
    max-width: 1060px;
    margin: 0 auto;

    @include g.mq(lg) {
      display: block;
    }
  }

  &__heading {
    text-align: center;
    color: g.$color-title;
    margin-bottom: 30px;
    line-height: 1.5;

    @include g.font-size(26);

    @include g.mq(md) {
      margin-bottom: 25px;

      @include g.font-size(20);
    }
  }

  &__list {
    margin-bottom: 39px;
    list-style: none;
    display: inline-block;

    @include g.mq(md) {
      max-width: 432px;
      margin: 0 auto 30px;
    }
  }

  &__item {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @include g.mq(md) {
      @include g.font-size(14);

      margin-bottom: 20px;
    }
  }

  &__link {
    display: block;
    @include g.font-size(14);
  }


}