@use "../../global" as g;

.c-relate {
  padding: 41px 15px 39px;
  background: g.$color-secondary;

  @include g.mq(md) {
    padding: 13px 47px 46px;
  }

  &__inner {
    max-width: 784px;
    margin: 0 auto;

    @include g.mq(md) {
      max-width: 500px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    @include g.mq(md) {
      display: block;
    }
  }

  &__item {
    width: calc((100% - 120px) / 4);
    margin: 0 40px 20px 0;

    @include g.mq(md) {
      width: 100%;
      margin-bottom: 1px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }

  &__head {
    @include g.mq(md) {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    @include g.font-size(11);

    display: -webkit-box;
    overflow: hidden;
    line-height: calc(14 / 9);
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @include g.mq(md) {
      display: none;
    }
  }

  &__link {
    display: block;
    position: relative;
    padding-bottom: 30px;
    transition: .3s ease;

    @include g.mq(md) {
      padding-right: 30px;
      padding-bottom: 0;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 19px;
      height: 19px;
      transition: all .3s ease;
      background: no-repeat 0 0 / auto 100%;

      @include g.mq(md) {
        top: 8px;
        right: 0;
        bottom: unset;
        left: unset;
        width: 17px;
        height: 17px;
      }
    }

    &::after {
      visibility: visible;
      opacity: 1;
      background-image: url("../images/common/ico_relate_btn_normal.png");
    }

    &::before {
      visibility: hidden;
      opacity: 0;
      background-image: url("../images/common/ico_relate_btn_hover.png");
    }

    &:hover {
      opacity: 1;

      &::after {
        visibility: hidden;
        opacity: 0;
      }

      &::before {
        visibility: visible;
        opacity: 1;
      }

      .c-relate__text {
        color: g.$color-title;
      }
    }
  }

  &__icon {
    width: 27px;
    height: 27px;
    margin-bottom: 10px;

    @include g.mq(md) {
      width: 25px;
      height: 25px;
      margin-right: 25px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__heading {
    display: -webkit-box;
    margin-bottom: 7px;
    overflow: hidden;
    color: g.$color-title;
    font-weight: 400;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    @include g.font-size(14);

    @include g.mq(md) {
/*      @include g.font-size(12);*/
      @include g.font-size(14);

      margin-bottom: 5px;
    }
  }
}

.c-relate--support {
  padding-top: 0;
  padding-bottom: 18px;

  @include g.mq(md) {
    padding-top: 24px;
    padding-bottom: 43px;
  }

  .c-relate__inner {
    position: relative;
    top: -37px;

    @include g.mq(md) {
      top: 0;
    }
  }
}
