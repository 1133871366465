@use "../../../global" as g;

.p-message-greetings {
  position: relative;
  padding: 100px 15px;

  @include g.mq(md) {
    padding: 60px 15px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1060px;
    margin: 0 auto;
    column-gap: 50px;

    @include g.mq(lg) {
      flex-direction: column;
      row-gap: 50px;
    }
  }

  &__image {
    margin: 0 auto;

    @include g.mq(lg) {
      width: 80%;
    }
  }

  &__image-item {
    position: relative;
    overflow: hidden;
    border-radius: 6px;

    @include g.mq(md) {}

  }

  &__content {
    position: relative;
    flex-shrink: 1;
    /*    padding:0 50px;*/
  }

  &__heading {
    margin-bottom: 40px;
    line-height: 1.5;
    font-weight: bold;

    @include g.font-size(26);

    @include g.mq(md) {
      margin-bottom: 25px;

      @include g.font-size(20);
    }

    &--annotation {
      @include g.font-size(14);
      color: g.$color-text;
    }
  }

  &__text {
    margin-bottom: 25px;

    @include g.mq(md) {
      margin-bottom: 20px;
    }
  }
}